import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { SectionTitle } from '#components'

function CalloutBar({ title, button }) {
  return (
    <div className="bg-gray-200 py-40 relative">
      <div className="container">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full xl:w-auto mb-20 xl:mb-0">
            <SectionTitle title={title} />
          </div>

          <Link
            className="text-19 bg-transparent text-red px-35 pt-7 pb-5 border-2 border-red inline-block text-center transition-all duration-300 hover:bg-red hover:text-white min-w-215 ml-8"
            to={button.url}
            target={button.target}
          >
            {button.title}
          </Link>
        </div>
      </div>
    </div>
  )
}

CalloutBar.propTypes = {
  title: PropTypes.string,
  button: PropTypes.object,
}

export default CalloutBar

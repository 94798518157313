import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import { SectionTitle } from '#components'

function AboutUs({ title, pretitle, description, imageField, contentBlocks, bottomContent, bottomTitle }) {
  return (
    <>
      <div className="relative overflow-hidden pt-175 pb-100 lg:pb-270 text-center">
        <div className="absolute inset-0">
          <img
            src={
              imageField.node
                ? imageField.node.localFile.childImageSharp.fluid.src
                : imageField.localFile.childImageSharp.fluid.src
            }
            alt="Section Highlight"
            className="w-full h-full absolute inset-0 object-cover object-center"
          />
          <div className="absolute inset-0 bg-ablack-200" />
          <div className="absolute inset-0 bg-gradient-br-semi-black-transparent" />
        </div>
        <div className="relative">
          <h2
            className="text-22 md:text-24 lg:text-27 xl:text-33 text-white font-bold"
            dangerouslySetInnerHTML={{ __html: pretitle }}
          ></h2>
          <h1
            className="mt-40 md:mt-100 lg:mt-150 xl:mt-225 font-bold text-white text-40 md:text-70 lg:text-100 xl:text-120 leading-none"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h1>
          <div
            className="mt-20 md:mt-30 text-white text-22 md:text-24 lg:text-33 xl:text-42"
            style={{ lineHeight: "1.18253968" }}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
      </div>
      <div className="bg-white relative pt-20 pb-50 md:pb-65">
        <div className="container-md">
          {contentBlocks &&
            contentBlocks.map((block) => (
              <div className="mt-50">
                <h2 className="sectiontitle text-22 md:text-24 lg:text-27 xl:text-33 font-bold leading-none mb-20 pl-30">
                  <span
                    style={{ backgroundColor: "#d90025", left: "0 !important" }}
                  />
                  <span dangerouslySetInnerHTML={{ __html: block.title }} />
                </h2>
                <div
                  className="content md:px-30"
                  dangerouslySetInnerHTML={{ __html: block.content }}
                />
                <img
                  src={
                    block.image.node
                      ? block.image.node.localFile.childImageSharp.fluid.src
                      : block.image.localFile.childImageSharp.fluid.src
                  }
                  alt="Section Highlight"
                  className="w-full h-auto block mt-40"
                />
              </div>
            ))}

          <div
            className="content md:px-30 mt-50"
            dangerouslySetInnerHTML={{ __html: bottomContent }}
          />
          <div
            style={{ color: "#d70025" }}
            className="md:px-30 mt-30 md:mt-40 lg:mt-60 text-22 md:text-33 lg:text-42 xl:text-56 font-bold leading-tight"
            dangerouslySetInnerHTML={{ __html: bottomTitle }}
          ></div>
        </div>
      </div>
    </>
  );
}

AboutUs.propTypes = {
  title: PropTypes.string,
  pretitle: PropTypes.string,
  description: PropTypes.string,
  imageField: PropTypes.object,
  bottomContent: PropTypes.string,
  bottomTitle: PropTypes.string,
  contentBlocks: PropTypes.array,
}

export default AboutUs

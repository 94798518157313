import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { useThemeOptions } from '#hooks'

const StyledLink = styled(Link)`
  display: inline-flex;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.3s ease;
    transform-origin: bottom right;
    transform: scaleX(0);
  }

  &:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  &.is-active {
    &::before {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
`

const StyledALink = styled.a`
  display: inline-flex;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.3s ease;
    transform-origin: bottom right;
    transform: scaleX(0);
  }

  &:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  &.is-active {
    &::before {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
`

export default function Footer() {
  const {
    footerColumn1Title,
    footerColumn2Title,
    footerColumn3Title,
    footerColumn4Title,
    footerColumn1Links,
    footerColumn2Links,
    footerColumn3Links,
    footerColumn4Links,
    address,
    phoneNumber,
    fax,
    email,
  } = useThemeOptions()

  return (
    <footer className="relative text-15 bg-gray-600 text-white pt-55 md:pt-65 lg:pt-85 pb-35 md:pb-45 lg:pb-85 overflow-hidden">
      <div className="container">
        <div className="flex flex-wrap justify-between">
          <div className="w-full xl:w-3/4 mb-30">
            <div className="flex flex-wrap md:flex-no-wrap -mx-7 md:-mx-15 xl:-mx-25">
              <div className="mb-20 md:mb-0 w-1/2 md:w-auto px-7 md:px-15 xl:px-25">
                <h3
                  className="font-bold"
                  dangerouslySetInnerHTML={{ __html: footerColumn1Title }}
                />
                <ul>
                  {footerColumn1Links &&
                    footerColumn1Links.map(({ link }) => (
                      <li className="block" key={link.title}>
                        {link.url.charAt(0) === `/` ? (
                          <StyledLink
                            className="text-14 lg:text-16 leading-normal"
                            to={link.url}
                            target={link.target}
                            dangerouslySetInnerHTML={{ __html: link.title }}
                          />
                        ) : (
                          <StyledALink
                            className="text-14 lg:text-16 leading-normal"
                            href={link.url}
                            target="_blank"
                            dangerouslySetInnerHTML={{ __html: link.title }}
                          />
                        )}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="mb-20 md:mb-0 w-1/2 md:w-auto px-7 md:px-15 xl:px-25">
                <h3
                  className="font-bold"
                  dangerouslySetInnerHTML={{ __html: footerColumn2Title }}
                />
                <ul>
                  {footerColumn2Links &&
                    footerColumn2Links.map(({ link }) => (
                      <li className="block" key={link.title}>
                        {link.url.charAt(0) === `/` ? (
                          <StyledLink
                            className="text-14 lg:text-16 leading-normal"
                            to={link.url}
                            target={link.target}
                            dangerouslySetInnerHTML={{ __html: link.title }}
                          />
                        ) : (
                          <StyledALink
                            className="text-14 lg:text-16 leading-normal"
                            href={link.url}
                            target="_blank"
                            dangerouslySetInnerHTML={{ __html: link.title }}
                          />
                        )}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="w-1/2 md:w-auto px-7 md:px-15 xl:px-25">
                <h3
                  className="font-bold"
                  dangerouslySetInnerHTML={{ __html: footerColumn3Title }}
                />
                <ul>
                  {footerColumn3Links &&
                    footerColumn3Links.map(({ link }) => (
                      <li className="block" key={link.title}>
                        {link.url.charAt(0) === `/` ? (
                          <StyledLink
                            className="text-14 lg:text-16 leading-normal"
                            to={link.url}
                            target={link.target}
                            dangerouslySetInnerHTML={{ __html: link.title }}
                          />
                        ) : (
                          <StyledALink
                            className="text-14 lg:text-16 leading-normal"
                            href={link.url}
                            target="_blank"
                            dangerouslySetInnerHTML={{ __html: link.title }}
                          />
                        )}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="w-1/2 md:w-auto px-7 md:px-15 xl:px-25">
                <h3
                  className="font-bold"
                  dangerouslySetInnerHTML={{ __html: footerColumn4Title }}
                />
                <ul>
                  {footerColumn4Links &&
                    footerColumn4Links.map(({ link }) => (
                      <li className="block" key={link.title}>
                        {link.url.charAt(0) === `/` ? (
                          <StyledLink
                            className="text-14 lg:text-16 leading-normal"
                            to={link.url}
                            target={link.target}
                            dangerouslySetInnerHTML={{ __html: link.title }}
                          />
                        ) : (
                          <StyledALink
                            className="text-14 lg:text-16 leading-normal"
                            href={link.url}
                            target="_blank"
                            dangerouslySetInnerHTML={{ __html: link.title }}
                          />
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full xl:flex xl:w-1/4 xl:pl-60 mb-30">
            <div className="w-full xl:ml-auto xl:max-w-270">
              <h3 className="font-bold">Auction House London</h3>
              <div
                className="leading-normal max-w-250"
                dangerouslySetInnerHTML={{ __html: address }}
              />
              <div className="pt-15">
                Tel:{` `}
                <StyledALink
                  className="text-white font-bold"
                  target="_blank"
                  href={`tel:${phoneNumber.replace(/\s/g, ``)}`}
                >
                  {phoneNumber}
                </StyledALink>
              </div>
              <div className="">
                Fax:{` `}
                <StyledALink
                  className="text-white font-bold"
                  target="_blank"
                  href={`tel:${fax.replace(/\s/g, ``)}`}
                >
                  {fax}
                </StyledALink>
                <div className="">
                  Email:{` `}
                  <StyledALink
                    className="text-white font-bold"
                    target="_blank"
                    href={`mailto:${email.replace(/\s/g, ``)}`}
                  >
                    {email}
                  </StyledALink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap justify-between pt-25 lg:pt-40 xl:pt-50">
          <div className="w-full lg:w-3/4 flex items-start">
            <div className="flex-shrink-0 pr-25 lg:pr-30">
              <img
                src="/images/logoalt.png"
                alt="Auction House London"
                width="58"
                height="58"
              />
            </div>
            <div className="text-14 xl:text-16">
              Site built by{` `}
              <a
                className="hover:underline"
                href="https://intelligentvc.co.uk"
                target="_blank"
              >
                IVC
              </a>
              <br />
              Copyright © {new Date().getFullYear()} Auction House London
              <br />
              Registered Address: 5 Hampstead Gate, 1a Frognal, Hampstead, London NW3 6AL
              Company No. 07048084
            </div>
          </div>
          <div className="w-full lg:flex lg:w-1/4 lg:pl-30 xl:pl-60 order-first lg:order-last pb-20 lg:pb-0">
            <div className="w-full lg:ml-auto lg:max-w-270">
              <img
                src="/images/footerlogos.png"
                alt="Footer Logos"
                width="220"
                height="57"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
// import { useScrollYPosition } from 'react-use-scroll-position'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Squash as Hamburger } from 'hamburger-react'

import useThemeOptions from '../../hooks/useThemeOptions'
import { OverlayMenu } from '#components'

const StyledLogo = styled(Link)`
  width: 75px;
  height: 75px;
  display: block;
  transition: all 0.3s;

  &.is-small {
    /* width: 75px !important; */
    /* height: 75px !important; */
    margin-bottom: -35px !important;
  }

  @media (min-width: 1280px) {
    width: 106px;
    height: 107px;
  }

  @media (max-width: 600px) {
    margin-top: 10px;
  }
`

const StyledALink = styled.a`
  display: inline-flex;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.3s ease;
    transform-origin: bottom right;
    transform: scaleX(0);
  }

  &:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  &.is-active {
    &::before {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }

  &.icon-link {
    &::before {
      display: none !important;
    }
  }
`

const StyledLink = styled(Link)`
  display: inline-flex;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.3s ease;
    transform-origin: bottom right;
    transform: scaleX(0);
  }

  &:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  &.is-active {
    &::before {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }

  &.icon-link {
    &::before {
      display: none !important;
    }
  }
`

export default function Header({ fixedHeader }) {
  const { phoneNumber, mainNavigation } = useThemeOptions()
  // const scrollY = useScrollYPosition()
  const [isOpen, setOpen] = useState(false)
  const [isVis, setIsVis] = useState(false)

  useScrollPosition(({ currPos }) => {
    setIsVis(currPos.y)
  })

  return (
    <>
      <header
        className={`fixed inset-x-0 top-0 z-100 transition-all duration-300 ${
          fixedHeader || isVis ? `bg-white shadow-md` : `bg-transparent`
        }`}
      >
        <div className="flex justify-between">
          <StyledLogo
            to="/"
            className={`flex-shrink-0 ${
              fixedHeader || isVis
                ? `is-small mt-20 ml-20 md:ml-30`
                : `mt-20 ml-20 md:ml-30`
            }`}
          >
            <img src="/images/logo.png" alt="Auction House London" />
          </StyledLogo>
          <nav className="flex">
            <ul
              className={`hidden lg:flex transition-all duration-300 ${
                fixedHeader || isVis ? `pt-31 pb-29` : `py-45`
              }`}
            >
              {mainNavigation.map((item, i) => (
                <li
                  className="ml-15 xl:ml-25 3xl:ml-40 4xl:ml-60 "
                  key={i + item.link.url}
                >
                  {item.subLinks ? (
                    <div className="relative c-submenu">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                        className={`text-15 xl:text-18 2xl:text-19 4xl:text-21 2xl:uppercase ${
                          fixedHeader || isVis ? `text-gray-700` : `text-white`
                        }`}
                        dangerouslySetInnerHTML={{__html:item.link.title}}
                      >
                      </a>
                      <div className="c-submenu__menu">
                        <section>
                          {item.subLinks.map((subItem, i) => (
                            <div>
                              <Link
                                to={subItem.link.url}
                                className="text-15 xl:text-18 2xl:text-19 4xl:text-21 text-white whitespace-nowrap"
                                dangerouslySetInnerHTML={{ __html: subItem.link.title }}
                              >
                              </Link>
                            </div>
                          ))}
                        </section>
                      </div>
                    </div>
                  ) : (
                    <StyledLink
                      to={item.link.url}
                      className={`text-15 xl:text-18 2xl:text-19 4xl:text-21 2xl:uppercase ${
                        fixedHeader || isVis ? `text-gray-700` : `text-white`
                      }`}
                      activeClassName="is-active"
                        dangerouslySetInnerHTML={{ __html: item.link.title }}
                    >
                    </StyledLink>
                  )}
                </li>
              ))}
            </ul>
            <div
              className={`flex items-center transition-all duration-300 ${
                fixedHeader || isVis
                  ? `pt-10 pb-10 md:pt-21 md:pb-20 bg-red pr-15 md:pr-20 ml-15 xl:ml-20 3xl:ml-40 4xl:ml-50`
                  : `pt-1 xl:pt-10 xl:pb-18 bg-transparent pr-15 md:pr-20 ml-15 xl:ml-20 3xl:ml-40 4xl:ml-50`
              }`}
            >
              <StyledLink
                className="ml-25 3xl:ml-20 4xl:ml-30 text-white icon-link"
                to="/favourites/  "
              >
                <span>
                  <img
                    src="/images/ifav.png"
                    alt="Your favourites"
                    width="21"
                    height="20"
                  />
                </span>
              </StyledLink>
              <StyledALink
                className="ml-15 md:ml-25 3xl:ml-20 text-white icon-link"
                href={`tel:${phoneNumber.replace(/\s/g, ``)}`}
              >
                <span>
                  <img
                    src="/images/ipho.png"
                    alt="Call us"
                    width="19"
                    height="19"
                  />
                </span>
              </StyledALink>
              {/* <StyledALink
                className="ml-15 md:ml-25 3xl:ml-20 text-white icon-link"
                href="https://wa.me/442076259007"
                target="_blank"
              >
                <span>
                  <img
                    src="/images/whatsapp.png"
                    alt="Call us"
                    width="21"
                    height="21"
                  />
                </span>
              </StyledALink> */}
              <StyledALink
                className="ml-15 md:ml-25 3xl:ml-20 text-white icon-link"
                href="mailto:info@auctionhouselondon.co.uk"
              >
                <span>
                  <img
                    src="/images/iema.png"
                    alt="Email us"
                    width="23"
                    height="17"
                  />
                </span>
              </StyledALink>
              <span className="text-white pl-20 lg:pl-30">
                <Hamburger toggled={isOpen} toggle={setOpen} />
              </span>
            </div>
          </nav>
        </div>
      </header>
      <OverlayMenu isOpen={isOpen} setOpen={setOpen} />
    </>
  )
}

Header.propTypes = {
  fixedHeader: PropTypes.bool,
}

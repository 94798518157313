import React from 'react'
import PropTypes from 'prop-types'

function Testimonial({
  accentColor,
  author,
  backgroundColor,
  padding,
  testimonial,
}) {
  const paddingClasses = padding === `medium` ? `pb-40 md:pb-60 lg:pb-85` : ``

  return (
    <div className="relative" style={{ backgroundColor }}>
      <div className={`container-small text-center ${paddingClasses}`}>
        <div
          className="uppercase text-20 md:text-24 lg:text-28 font-semibold leading-tight"
          style={{ color: accentColor }}
          dangerouslySetInnerHTML={{ __html: testimonial }}
        />
        <h4
          className="text-16 md:text-18 lg:text-21 pt-10"
          dangerouslySetInnerHTML={{ __html: author }}
        />
      </div>
    </div>
  )
}

Testimonial.defaultProps = {}

Testimonial.propTypes = {
  accentColor: PropTypes.string,
  author: PropTypes.string,
  backgroundColor: PropTypes.string,
  padding: PropTypes.string,
  testimonial: PropTypes.string,
}

export default Testimonial

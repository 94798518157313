import React from 'react'
import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import Img from 'gatsby-image'

import { ArrowButton } from '#components'

const stripePromise = loadStripe(`pk_live_XBopzyTKhBGBASlL1XlkXvyI`)

function ContentArea({
  accentColor,
  content,
  padding,
  title,
  stripeCheckout,
  imageLinks,
  showSidebar,
  sidebarButtons,
}) {
  const paddingClasses =
    padding === `medium`
      ? `py-40 md:py-60 lg:py-85`
      : padding === `large`
      ? `py-50 md:py-70 lg:py-100 xl:py-125`
      : ``

  const handleClick = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise
    const {
      data: { id },
    } = await axios.get(
      `https://admin.auctionhouselondon.co.uk/wp-json/stripe/create-session`
    )
    const { error } = await stripe.redirectToCheckout({
      sessionId: id,
    })
  }

  return (
    <div
      className={`bg-white relative s-contentarea ${
        padding === `large` ? `s-contentarea--lg` : ``
      }`}
    >
      <div className={`container ${paddingClasses}`}>
        {stripeCheckout && (
          <div className="pb-50 md:pb-80 xl:pb-100 text-center">
            <ArrowButton variant="accent" onClick={handleClick}>
              Checkout
            </ArrowButton>
          </div>
        )}
        {!showSidebar && title && (
          <h2 className="sectiontitle text-22 md:text-24 lg:text-27 xl:text-33 font-bold leading-none mb-30 md:mb-40 lg:mb-50">
            <span style={{ backgroundColor: accentColor }} />
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </h2>
        )}
        {!showSidebar && content && (
          <div
            className={`content ${
              accentColor === `#99cc00` || accentColor === `#99cd00`
                ? `content--green`
                : ``
            }`}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {showSidebar && content && (
          <div className="flex flex-wrap -mx-20">
            <div className="w-full md:w-3/4 px-20 lg:pr-40 xl:pr-60">
              <h2 className="sectiontitle text-22 md:text-24 lg:text-27 xl:text-33 font-bold leading-none mb-30 md:mb-40 lg:mb-50">
                <span style={{ backgroundColor: accentColor }} />
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </h2>
              <div
                className={`content ${
                  accentColor === `#99cc00` || accentColor === `#99cd00`
                    ? `content--green`
                    : ``
                }`}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
            <div className="w-full order-first md:order-last md:w-1/4 px-20">
              {sidebarButtons &&
                sidebarButtons.map((item) => (
                  <div className="mb-10" key={item.button.url}>
                    <a
                      href={item.button.url}
                      target={item.button.target}
                      rel="noreferrer"
                      className={`text-17 lg:text-19 px-20 pt-9 pb-6 block text-center border-2 transition-all duration-300 mb-8 ${
                        item.buttonStyle === `accent`
                          ? `bg-red text-white hover:text-gray-700 border-red hover:bg-white hover:border-gray-700`
                          : `hover:shadow-md hover:border-gray-600 hover:bg-gray-600 hover:text-white border-gray-700`
                      }`}
                      dangerouslySetInnerHTML={{ __html: item.button.title }}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
        {imageLinks && (
          <div className="flex flex-wrap -mx-12">
            {imageLinks.map((link) => (
              <div className="w-full md:w-1/2 px-12 mb-25">
                <a
                  href={link.url}
                  target="_blank"
                  rel="noreferrer"
                  className="block w-full h-full relative transition-all duration-300 hover:shadow-post"
                >
                  <Img
                    alt="Service"
                    fluid={link.image.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-full h-full"
                  />
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

ContentArea.propTypes = {
  accentColor: PropTypes.string,
  content: PropTypes.string,
  padding: PropTypes.string,
  title: PropTypes.string,
  stripeCheckout: PropTypes.bool,
  imageLinks: PropTypes.array,
  showSidebar: PropTypes.bool,
  sidebarButtons: PropTypes.array,
}

export default ContentArea

import React from 'react'
import PropTypes from 'prop-types'
import {
  GoogleMap as ReactGoogleMaps,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'

import mapOptions from './mapOptions'

function GoogleMap({ height, latitude, longitude }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `AIzaSyAbhJwdrGNW8pJSqJ29tMS0hWJ0syTakBw`,
  })

  const renderMap = () => (
    <ReactGoogleMaps
      zoom={12}
      mapContainerStyle={{
        height,
        width: `100%`,
      }}
      center={{
        lat: latitude,
        lng: longitude,
      }}
      options={mapOptions}
    >
      <Marker
        position={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }}
        icon="/images/marker.png"
      />
    </ReactGoogleMaps>
  )

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : null
}

GoogleMap.defaultProps = {
  height: `315px`,
}

GoogleMap.propTypes = {
  height: PropTypes.string,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
}

export default GoogleMap

export default {
  scrollwheel: false,
  styles: [
    { featureType: `road`, stylers: [{ hue: `#761317` }, { saturation: -10 }] },
    {
      featureType: `poi`,
      stylers: [
        { saturation: -78 },
        { hue: `#dd1522` },
        { lightness: -47 },
        { visibility: `off` },
      ],
    },
    { featureType: `road.local`, stylers: [{ lightness: 22 }] },
    {
      featureType: `landscape`,
      stylers: [{ hue: `#dd1522` }, { saturation: -11 }],
    },
    {},
    {},
    {
      featureType: `water`,
      stylers: [{ saturation: -15 }, { hue: `#dd1522` }, { lightness: 8 }],
    },
    {
      featureType: `road.local`,
      stylers: [{ weight: 1.3 }, { lightness: 30 }],
    },
    {
      featureType: `transit`,
      stylers: [
        { visibility: `simplified` },
        { hue: `#dd1522` },
        { saturation: -16 },
      ],
    },
    { featureType: `transit.line`, stylers: [{ saturation: -72 }] },
    {},
  ],
}

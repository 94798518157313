import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { LinkLine, ArrowButton, SectionTitle } from '#components'

const StyledCheckbox = styled.div`
  position: relative;
  overflow: hidden;
  font-size: 16px;

  input {
    position: absolute;
    left: -9999px;

    &:checked ~ label {
      &::after {
        opacity: 1;
      }
    }
  }

  label {
    display: block;
    position: relative;
    padding-left: 38px;
    cursor: pointer;

    &::before {
      content: '';
      width: 23px;
      height: 23px;
      border: 2px solid #fff;
      position: absolute;
      top: 0.3em;
      left: 0;
      transition: all 0.3s;
    }

    &::after {
      content: '';
      width: 23px;
      height: 23px;
      position: absolute;
      border: 2px solid #fff;
      top: 0.3em;
      left: 0;
      transition: all 0.3s;
      opacity: 0;
      background: #fff;
    }

    &:hover {
      &::before {
        border: 2px solid rgba(255, 255, 255, 0.8);
      }
    }
  }

  &.is-dark {
    label {
      &::before {
        border: 2px solid #c8102e;
      }

      &::after {
        border: 2px solid #c8102e;
        background: #c8102e;
      }

      &:hover {
        &::before {
          border: 2px solid #c8102e;
          opacity: 0.8;
        }
      }
    }
  }
`

export default function NewsletterForm({ title, description, accentColor }) {
  return (
    <div
      className={`bg-red relative pt-50 md:pt-60 pb-50 lg:pb-70 z-10 ${
        accentColor === `#f5f5f5`
          ? `md:py-90 lg:py-120 xl:py-150 text-black`
          : `text-white`
      }`}
      style={{ backgroundColor: accentColor }}
    >
      <div className="container">
        {accentColor === `#f5f5f5` ? (
          <div className="mb-30">
            <SectionTitle title={title} />
          </div>
        ) : (
          <h2
            className="text-24 md:text-28 lg:text-32 font-bold leading-tight text-center md:text-left"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <div
          className={`text-18 md:text-20 lg:text-24 xl:text-32 leading-normal text-center md:text-left ${
            accentColor === `#f5f5f5` ? `md:pl-30` : ``
          }`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <form
          action="https://auctionhouse-london.co.uk/signup.ashx"
          className="pt-25 lg:pt-40"
          method="post"
        >
          <div className="flex flex-wrap -mx-7">
            <div className="w-full md:w-1/2 px-7 mb-30">
              <input
                type="text"
                name="cd_SALUTATION"
                id="newsletter-name"
                placeholder="Name*"
                className="block w-full rounded-none bg-white text-black leading-normal placeholder-gray-900 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-outline-gray focus:shadow-outline-gray focus:border-gray-500 outline-none"
              />
            </div>
            <div className="w-full md:w-1/2 px-7 mb-30">
              <input
                type="email"
                name="Email"
                id="newsletter-email"
                placeholder="Email*"
                className="block w-full rounded-none bg-white text-black leading-normal placeholder-gray-900 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-outline-gray focus:shadow-outline-gray focus:border-gray-500 outline-none"
              />
            </div>
            <div
              className={`flex flex-wrap w-full ${
                accentColor === `#f5f5f5` ? `pt-20` : ``
              }`}
            >
              <div
                className={`w-full text-16 md:w-1/2 px-7 flex items-center `}
              >
                <StyledCheckbox
                  className={`max-w-620 ${
                    accentColor === `#f5f5f5` ? `is-dark` : ``
                  }`}
                >
                  <input type="checkbox" name="consent" id="consent" />
                  <label htmlFor="consent">
                    Tick this box to confirm you have read our{` `}
                    <LinkLine to="/privacy-policy">Privacy Policy</LinkLine>,
                    and to also confirm you are okay with receiving email
                    correspondance from us.{` `}
                  </label>
                </StyledCheckbox>
              </div>
              <div className="w-full md:w-1/2 px-7 text-center md:text-right pt-30 md:pt-0">
                <ArrowButton
                  variant={accentColor === `#f5f5f5` ? `accent` : `ghost`}
                  type="submit"
                >
                  Submit
                </ArrowButton>
              </div>
            </div>
          </div>
          <input name="addressbookid" type="hidden" value="2266557" />
          <input name="userid" type="hidden" value="67740" />
          <input
            name="ReturnURL"
            type="hidden"
            value="https://auctionhouselondon.co.uk/thank-you-for-joining/"
          />
        </form>
      </div>
    </div>
  )
}

NewsletterForm.defaultProps = {
  title: `Sign-up for our newsletter`,
  description: `<p>Get the latest lot details along with new blog posts, straight to your inbox</p>`,
}

NewsletterForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  accentColor: PropTypes.string,
}

import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import InputWrapper from '../InputWrapper'

const GravitySelect = ({
  errors,
  fieldData,
  name,
  register,
  control,
  ...wrapProps
}) => {
  const { choices, cssClass, isRequired, size, placeholder } = fieldData
  const options = JSON.parse(choices)
  const selectOptions = options.map(({ isSelected, text, value }) => {
    const option = {
      isSelected,
      value,
      label: text,
    }

    return option
  })

  return (
    <InputWrapper
      errors={errors}
      inputData={fieldData}
      labelFor={name}
      {...wrapProps}
    >
      {/* <select
        aria-invalid={errors}
        aria-required={isRequired}
        // TODO: GF uses select2 library and classes, need to figure out how to handle here if we're mimicing their functionality
        className={classnames(
          `gravityform__field__input`,
          `gravityform__field__input__select`,
          `gfield_select`,
          cssClass,
          size
        )}
        id={name}
        name={name}
        ref={register({
          required: isRequired && `This field is required`,
        })}
      >
        {options.map(({ isSelected, text, value }, index) => (
          <option
            defaultValue={isSelected}
            key={`${name}-${index}`}
            value={value}
          >
            {text}
          </option>
        ))}
      </select> */}
      <Controller
        id={name}
        name={name}
        classNamePrefix="c-select"
        className={classnames(
          `gravityform__field__input`,
          `gravityform__field__input__select`,
          `gfield_select`,
          `c-select`,
          cssClass,
          size
        )}
        as={Select}
        options={selectOptions}
        placeholder={placeholder}
        control={control}
        defaultValue=""
        rules={{
          required: isRequired && `This field is required`,
        }}
        styles={{
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,

            ':active': {
              ...styles[`:active`],
              backgroundColor: `#f1f3f6`,
            },
          }),
        }}
      />
    </InputWrapper>
  )
}

export default GravitySelect

GravitySelect.propTypes = {
  errors: PropTypes.object,
  fieldData: PropTypes.shape({
    choices: PropTypes.string,
    cssClass: PropTypes.string,
    isRequired: PropTypes.bool,
    size: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  name: PropTypes.string,
  register: PropTypes.func,
  control: PropTypes.object,
  wrapProps: PropTypes.object,
}

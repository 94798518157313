import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import slugify from 'slugify'
import { Link } from 'gatsby'
import { ics } from 'calendar-link'
import { BsDownload } from 'react-icons/bs'
import moment from 'moment'

import {
  useFavoriteActionsContext,
  useFavoriteContext,
} from '../../store/FavoriteContext'

const StyledLotBlock = styled.div`
  .postblock__img {
    transition: all 4s;
  }

  &:hover {
    .postblock__img {
      transform: scale(1.1);
    }
  }

  .postblock__link {
    span {
      &.postblock__linktitle {
        display: inline-flex;
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          bottom: 2px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: currentColor;
          transition: transform 0.3s ease;

          transform-origin: bottom right;
          transform: scaleX(0);
        }
      }

      &.postblock__linkarrow {
        transition: all 0.3s;
      }

      &.postblock__linkplus {
        position: relative;
        top: 2px;
        transition: all 1s;
      }
    }

    &:hover {
      span {
        &.postblock__linktitle {
          &::before {
            transform-origin: bottom left;
            transform: scaleX(1);
          }
        }

        &.postblock__linkarrow {
          transform: translateX(6px);
        }

        &.postblock__linkplus {
          transform: rotate(360deg);
        }
      }
    }
  }
`

export default function LotBlock({ lot, truncated, resultPrice, noviewing }) {
  const {
    removeFavoriteItem,
    addFavoriteItem,
    checkFavoriteItem,
  } = useFavoriteActionsContext()
  const favourites = useFavoriteContext()
  const image = lot.Thumbnail.replace(`_enhanced_thumb`, `_web_medium`).replace(
    `_thumb`,
    `_web_medium`
  )

  const slug = `/lot/${slugify(lot.FullAddress, {
    lower: true,
    strict: true,
  })}-${lot.ID}/`

  const nextViewing = !truncated
    ? lot.ViewingTimes.sort(
        (a, b) => new Date(a.StartDate) - new Date(b.StartDate)
      ).filter((a) => new Date(a.EndDate) >= new Date())[0]
    : ``

  // const formattedNextViewing = !truncated
  //   ? nextViewing
  //     ? `${new Intl.DateTimeFormat(`en-UK`, {
  //         weekday: `long`,
  //         month: `long`,
  //         day: `numeric`,
  //         hour: `numeric`,
  //         minute: `numeric`,
  //         hour12: false,
  //       }).format(new Date(nextViewing.StartDate))}-${new Intl.DateTimeFormat(
  //         `en-UK`,
  //         {
  //           hour: `numeric`,
  //           minute: `numeric`,
  //           hour12: false,
  //         }
  //       ).format(new Date(nextViewing.EndDate))}`
  //     : ``
  //   : ``

  const formattedNextViewing = !truncated
    ? nextViewing
      ? `${moment(nextViewing.StartDate).format(
          `dddd Do MMMM - HH:mm`
        )}-${new Intl.DateTimeFormat(`en-UK`, {
          hour: `numeric`,
          minute: `numeric`,
          hour12: false,
        }).format(new Date(nextViewing.EndDate))}`
      : ``
    : ``

  return (
    <StyledLotBlock className="relative flex flex-col md:h-full w-full border-t-6 border-red transition-all duration-300 hover:shadow-post">
      <Link className="relative flex flex-col h-full w-full" to={slug}>
        <header className="h-225 md:h-300 relative overflow-hidden bg-gray-200">
          <div
            className="absolute inset-0 postblock__img"
            style={{
              background: `url(${image}) no-repeat center / contain`,
            }}
          />
          {(lot.GuidePrice === `SOLD PRIOR` ||
            lot.GuidePrice === `Sold Prior` ||
            lot.GuidePrice === `Sold prior`) && (
            <span className="c-soldprior bg-red text-white absolute">
              Sold Prior
            </span>
          )}
        </header>
        {lot.LotNumber && (
          <div className="absolute left-0 top-0 bg-red text-white text-17 md:text-19 uppercase px-25 py-6">
            Lot {lot.LotNumber}
          </div>
        )}
        <div
          className={`pt-23 px-10 flex justify-between ${
            !truncated ? `pb-10` : `pb-20`
          }`}
        >
          <div className="flex-grow">
            {!truncated ? (
              <h3
                className={`text-17 lg:text-19 leading-snug ${
                  lot.ResultPrice ? `font-semibold` : `font-bold`
                }`}
                dangerouslySetInnerHTML={{ __html: lot.FullAddress }}
              />
            ) : (
              <h3 className="text-17 lg:text-19 leading-snug">
                <span
                  className={`block ${
                    lot.ResultPrice
                      ? `lg:text-19 font-normal`
                      : `lg:text-23 font-semibold`
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: lot.FullAddress.slice(
                      0,
                      lot.FullAddress.indexOf(`,`)
                    ),
                  }}
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: lot.FullAddress.slice(
                      lot.FullAddress.indexOf(`,`) + 1
                    ),
                  }}
                />
              </h3>
            )}
          </div>
          {lot.SoldStatus === `Withdrawn` ? (
            <div className="w-auto max-w-220 text-right">
              <p className="pl-20 text-18 leading-snug text-red font-semibold">
                *Withdrawn
              </p>
            </div>
          ) : lot.ResultPrice ? (
            <div className="w-auto max-w-220 text-right">
              <p className="pl-20 text-18 leading-snug text-red font-semibold">
                *{lot.ResultPrice}
              </p>
            </div>
          ) : (
            <div className="flex-shrink-0">
              <p className="pl-20 text-19 lg:text-23 text-red font-semibold">
                *{lot.GuidePrice}
              </p>
            </div>
          )}
        </div>
        {!truncated && (
          <div className="pb-17 px-10">
            <p
              className="text-17 lg:text-19 leading-snug"
              dangerouslySetInnerHTML={{ __html: lot.TagLine }}
            />
          </div>
        )}
      </Link>
      {!truncated && !noviewing && (
        <div className="flex border-t-2 border-gray-900 justify-between">
          <div className="px-10 pt-16 pb-10 lg:min-h-96">
            <p className="text-14">Next Viewing</p>
            {nextViewing ? (
              <>
                <h4 className="text-16 lg:text-19 font-semibold leading-none -mb-5 pt-2">
                  {formattedNextViewing}
                </h4>
                <a
                  href={ics({
                    title: `Viewing for ${lot.FullAddress}`,
                    start: nextViewing.StartDate,
                    end: nextViewing.EndDate,
                  })}
                  target="_blank"
                  rel="noreferrer"
                  className="text-red inline-flex leading-normal items-center postblock__link font-bold text-15"
                >
                  <span className="block text-20 mr-10 mb-3 postblock__linkplus">
                    +
                  </span>
                  <span className="block postblock__linktitle">
                    Add to Calendar
                  </span>
                </a>
              </>
            ) : (
              lot.ViewingTimeDescription && (
                <h4
                  className="text-16 lg:text-19 font-semibold leading-tight"
                  dangerouslySetInnerHTML={{
                    __html: lot.ViewingTimeDescription.substr(
                      0,
                      lot.ViewingTimeDescription.indexOf(`</b>`)
                    ),
                  }}
                />
              )
            )}
          </div>
          <a
            href={lot.LegalDocumentUrl}
            target="_blank"
            rel="noreferrer"
            className="flex-shrink-0 w-96 flex flex-col justify-center items-center bg-gray-200 text-center transition-all duration-300 hover:shadow-md group"
          >
            <span className="text-15 leading-tight font-semibold py-7">
              <span className="text-center text-20 lg:text-27 pb-10 group-hover:text-red leading-none flex justify-center transition-all duration-300">
                <BsDownload />
              </span>
              Download Legal Pack
            </span>
          </a>
        </div>
      )}
      <div className="flex justify-between items-center border-b-3 border-t-2 border-gray-900 py-4 text-15 font-bold pl-10 pr-18 bg-gray-200">
        {!noviewing && (
          <button
            type="button"
            onClick={() => {
              if (favourites.findIndex((item) => item.ID === lot.ID) !== -1) {
                removeFavoriteItem(lot)
              } else {
                addFavoriteItem(lot)
              }
            }}
            className="inline-flex items-center postblock__link font-bold text-15"
          >
            <span className="block text-20 mr-10 mb-3 postblock__linkplus">
              +
            </span>
            <span className="block postblock__linktitle">
              {favourites.findIndex((item) => item.ID === lot.ID) !== -1
                ? `Remove`
                : `Add`}
              {` `}
              Favourite
            </span>
          </button>
        )}
        <Link
          to={slug}
          className="inline-flex items-center postblock__link ml-auto"
        >
          <span className="block postblock__linktitle">View Details</span>
          <span className="block text-20 ml-15 mb-3 postblock__linkarrow">
            →
          </span>
        </Link>
      </div>
    </StyledLotBlock>
  )
}

LotBlock.propTypes = {
  lot: PropTypes.object,
  truncated: PropTypes.bool,
  noviewing: PropTypes.bool,
  resultPrice: PropTypes.bool,
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'

import LinkLine from '../../components/LinkLine'
import ArrowButton from '../../components/ArrowButton'
import SectionTitle from '../../components/SectionTitle'
import GravityForm from '../../components/GravityForm'
import { useGravityData } from '#hooks'

const StyledCheckbox = styled.div`
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    left: -9999px;

    &:checked ~ label {
      &::after {
        opacity: 1;
      }
    }
  }

  label {
    display: block;
    position: relative;
    padding-left: 38px;
    cursor: pointer;

    &::before {
      content: '';
      width: 23px;
      height: 23px;
      border: 2px solid
        ${(props) =>
          props.accentColor === `#99cc00` || props.accentColor === `#99cd00`
            ? `#9c0`
            : `#c8102e`};
      position: absolute;
      top: 0.3em;
      left: 0;
      transition: all 0.3s;
    }

    &::after {
      content: '';
      width: 23px;
      height: 23px;
      position: absolute;
      border: 2px solid
        ${(props) =>
          props.accentColor === `#99cc00` || props.accentColor === `#99cd00`
            ? `#9c0`
            : `#c8102e`};
      top: 0.3em;
      left: 0;
      transition: all 0.3s;
      opacity: 0;
      background: ${(props) =>
        props.accentColor === `#99cc00` || props.accentColor === `#99cd00`
          ? `#9c0`
          : `#c8102e`};
    }

    &:hover {
      &::before {
        border: 2px solid
          ${(props) =>
            props.accentColor === `#99cc00` || props.accentColor === `#99cd00`
              ? `rgba(153, 204, 0, 0.6)`
              : `rgba(251, 2, 34, 0.6)`};
      }
    }
  }
`

export default function FormSection({
  title,
  formId,
  accentColor,
  enquiry,
  notice,
  presetValues,
}) {
  function handleError({ values, error, reset }) {
    console.log(values)
    console.log(error)
  }

  function handleSuccess({ values, reset, confirmations }) {
    if (typeof window !== `undefined`) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmission'
      });
    }

    // handle success
    if (formId === `2`) {
      navigate(`/thank-you-valuation/`)
    } else if (formId === `4`) {
      navigate(`/thank-you-enquiry/`)
    } else if (formId === `3`) {
      navigate(`/stripe/`)
    } else {
      navigate(`/thank-you/`)
    }
  }
  const formData = useGravityData()
  const showForm =
    formId === `3` ||
    formId === `2` ||
    formId === `1` ||
    formId === `4` ||
    formId === `6` ||
    formId === `5`
  // console.log(
  //   JSON.parse(allGfForm.edges[0].node.formFields[39].conditionalLogic)
  // )

  return (
    <div className="relative z-10 bg-gray-200 py-55 md:py-65 lg:py-75 xl:py-85">
      <div
        className={`${
          showForm && formId !== `1` && formId !== `4`
            ? `container-small`
            : `container contactform`
        }`}
      >
        {title && (
          <div className="mb-40">
            {formId === `2` ||
            formId === `3` ||
            formId === `5` ||
            formId === `6` ? (
              <SectionTitle title={title} />
            ) : (
              <h2
                className={`text-24 sm:text-28 md:text-32 font-bold leading-tight mb-25 text-center md:text-left text-${
                  accentColor === `#99cc00` || accentColor === `#99cd00`
                    ? `green`
                    : `red`
                }`}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
          </div>
        )}

        {showForm ? (
          <div>
            <div style={{ marginTop: `20px` }}>
              <div
                className={`block relative ${
                  formId === `3` ? `registertobid` : ``
                }`}
              >
                <GravityForm
                  id={parseInt(formId)}
                  formData={formData}
                  lambda={`https://admin.auctionhouselondon.co.uk/wp-json/llgf/v1/form/${formId}`}
                  successCallback={handleSuccess}
                  errorCallback={handleError}
                  presetValues={presetValues}
                />
              </div>
              {formId === `2` && (
                <a
                  href="https://wa.me/442076259007"
                  target="_blank"
                  rel="noreferrer"
                  style={{ height: `56px` }}
                  className="mt-20 font-bold bg-whatsapp text-white text-20 hover:bg-whatsapphover w-full flex items-center justify-center transition-all duration-300"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      ariaHidden="true"
                      className="svg-inline--fa fa-whatsapp fa-w-14 fa-9x"
                      data-icon="whatsapp"
                      data-prefix="fab"
                      viewBox="0 0 448 512"
                      style={{ width: `34px` }}
                    >
                      <path
                        fill="currentColor"
                        d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                      />
                    </svg>
                  </span>
                  <span className="ml-20 text-18 text-white">
                    Enquire via WhatsApp
                  </span>
                </a>
              )}
            </div>
          </div>
        ) : formId === `2` ? (
          <form action="#" className="mt-30 md:mt-55">
            <div className="flex flex-wrap -mx-7">
              <div className="w-full px-7 mb-30">
                <input
                  type="text"
                  name="address-lookup"
                  id="address-lookup"
                  placeholder="Address Lookup"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 px-7 mb-14">
                <input
                  type="text"
                  name="house-name"
                  id="house-name"
                  placeholder="House Name / Number *"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 px-7 mb-14">
                <input
                  type="text"
                  name="street"
                  id="street"
                  placeholder="Street *"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 px-7 mb-14">
                <input
                  type="text"
                  name="town"
                  id="town"
                  placeholder="Town"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 px-7 mb-14">
                <input
                  type="text"
                  name="county"
                  id="county"
                  placeholder="County"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 px-7 mb-14">
                <input
                  type="text"
                  name="no-bedrooms"
                  id="no-bedrooms"
                  placeholder="No. of Bedrooms"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 px-7 mb-14">
                <input
                  type="text"
                  name="years-remaining"
                  id="years-remaining"
                  placeholder="Years remaining on lease (if applicable)"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 px-7 mb-14">
                <input
                  type="text"
                  name="reserve-price"
                  id="reserve-price"
                  placeholder="Preferred reserve price"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 px-7 mb-14">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  placeholder="First name"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 px-7 mb-14">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  placeholder="Last Name"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 px-7 mb-14">
                <input
                  type="text"
                  name="telephone"
                  id="telephone"
                  placeholder="Telephone"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full px-7 mb-14">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full px-7 mb-30">
                <textarea
                  name="contact-enquiry"
                  id="contact-enquiry"
                  placeholder="Enquiry"
                  rows="6"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-18 pb-16 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              {!!notice && (
                <div className="pt-20 mb-10 px-7">
                  <div
                    className="content content--small max-w-880"
                    dangerouslySetInnerHTML={{ __html: notice }}
                  />
                </div>
              )}
              <div className="w-full md:w-1/2 px-7 flex items-center">
                <StyledCheckbox className="max-w-620" accentColor={accentColor}>
                  <input
                    type="checkbox"
                    name="contact-consent"
                    id="contact-consent"
                  />
                  {!notice ? (
                    <label htmlFor="contact-consent">
                      Tick this box to confirm you have read our{` `}
                      <LinkLine to="/terms-conditions">
                        Terms & Conditions
                      </LinkLine>
                      {` `}and our{` `}
                      <LinkLine to="/privacy-policy">Privacy Policy</LinkLine>,
                      and to also confirm you are okay with receiving email
                      correspondance from us.{` `}
                    </label>
                  ) : (
                    <label
                      htmlFor="contact-consent"
                      className="pt-7 pb-10 text-14"
                    >
                      Please click here to confirm that you agree to all terms
                      presented above
                    </label>
                  )}
                </StyledCheckbox>
              </div>
              <div className="w-full md:w-1/2 px-7 md:text-right pt-30 text-center md:pt-0">
                <ArrowButton
                  variant={
                    accentColor === `#99cc00` || accentColor === `#99cd00`
                      ? `green`
                      : `accent`
                  }
                  type="submit"
                >
                  Submit
                </ArrowButton>
              </div>
            </div>
          </form>
        ) : (
          <form action="#">
            <div className="flex flex-wrap -mx-7">
              <div className="w-full md:w-1/2 lg:w-1/4 px-7 mb-10 lg:mb-25">
                <input
                  type="text"
                  name="contact-first-name"
                  id="contact-first-name"
                  placeholder="First Name"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 px-7 mb-10 lg:mb-25">
                <input
                  type="text"
                  name="contact-last-name"
                  id="contact-last-name"
                  placeholder="Last Name"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 px-7 mb-10 lg:mb-25">
                <input
                  type="text"
                  name="contact-phone"
                  id="contact-phone"
                  placeholder="Telephone No."
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 px-7 mb-10 lg:mb-25">
                <input
                  type="text"
                  name="contact-email"
                  id="contact-email"
                  placeholder="Email"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-13 pb-11 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              <div className="w-full px-7 mb-30">
                <textarea
                  name="contact-enquiry"
                  id="contact-enquiry"
                  placeholder="Enquiry"
                  rows="6"
                  className={`block w-full border-b-3 border-gray-400 rounded-none bg-white text-black leading-normal placeholder-gray-800 text-19 tracking-wide px-25 pt-18 pb-16 transition-all duration-300 hover:shadow-md focus:border-${
                    accentColor === `#99cc00` || accentColor === `#`
                      ? `green`
                      : `red`
                  } focus:shadow-md outline-none`}
                />
              </div>
              {!!notice && (
                <div className="pt-20 mb-10 px-7">
                  <div
                    className="content content--small max-w-880"
                    dangerouslySetInnerHTML={{ __html: notice }}
                  />
                </div>
              )}
              <div className="w-full md:w-1/2 px-7 flex items-center">
                <StyledCheckbox className="max-w-620" accentColor={accentColor}>
                  <input
                    type="checkbox"
                    name="contact-consent"
                    id="contact-consent"
                  />
                  {!notice ? (
                    <label htmlFor="contact-consent">
                      Tick this box to confirm you have read our{` `}
                      <LinkLine to="/terms-conditions">
                        Terms & Conditions
                      </LinkLine>
                      {` `}and our{` `}
                      <LinkLine to="/privacy-policy">Privacy Policy</LinkLine>,
                      and to also confirm you are okay with receiving email
                      correspondance from us.{` `}
                    </label>
                  ) : (
                    <label
                      htmlFor="contact-consent"
                      className="pt-7 pb-10 text-14"
                    >
                      Please click here to confirm that you agree to all terms
                      presented above
                    </label>
                  )}
                </StyledCheckbox>
              </div>
              <div className="w-full md:w-1/2 px-7 md:text-right pt-30 text-center md:pt-0">
                <ArrowButton
                  variant={
                    accentColor === `#99cc00` || accentColor === `#99cd00`
                      ? `green`
                      : `accent`
                  }
                  type="submit"
                >
                  Submit
                </ArrowButton>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

FormSection.defaultProps = {
  title: `Contact Us`,
  formId: `1`,
}

FormSection.propTypes = {
  title: PropTypes.string,
  formId: PropTypes.string,
  accentColor: PropTypes.string,
  enquiry: PropTypes.bool,
  notice: PropTypes.string,
  presetValues: PropTypes.object,
}

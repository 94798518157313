import React from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";

const StyledSection = styled.div`
  .wrapper {
    padding-top: 50px;
    padding-bottom: 60px;

    @media (min-width: 768px) {
      padding-top: 80px;
      padding-bottom: 100px;
    }
  }

  .container {
    max-width: 1040px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;

    h2 {
      text-align: center;
      font-weight: bold;
      font-size: 19px;
      line-height: 1.5;

      @media (min-width: 768px) {
        font-size: 26px;
      }
    }

    .content {
      margin-top: 50px;
      font-size: 18px;
      line-height: 1.5;
      font-weight: 300;

      @media (min-width: 768px) {
        font-size: 22px;
      }

      p {
        + p {
          margin-top: 20px;
        }
      }
    }
  }
`;

function NewIntro({
  title,
  content
}) {
  return (
    <StyledSection>
      <div className="bg-white relative wrapper">
        <div className="container">
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
          {content && <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>}
        </div>
      </div>
    </StyledSection>
  );
}

NewIntro.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default NewIntro;

import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import moment from 'moment'

const StyledPostBlock = styled(Link)`
  display: block;

  .postblock__img {
    transition: all 4s;
  }

  &:hover {
    .postblock__img {
      transform: scale(1.1);
    }
  }

  .postblock__link {
    span {
      &:nth-of-type(1) {
        display: inline-flex;
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          bottom: 2px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: currentColor;
          transition: transform 0.3s ease;

          transform-origin: bottom right;
          transform: scaleX(0);
        }
      }

      &:nth-of-type(2) {
        transition: all 0.3s;
      }
    }

    &:hover {
      span {
        &:nth-of-type(1) {
          &::before {
            transform-origin: bottom left;
            transform: scaleX(1);
          }
        }

        &:nth-of-type(2) {
          transform: translateX(6px);
        }
      }
    }
  }
`

export default function PostBlock({ size, post }) {
  return (
    <StyledPostBlock
      to={post.uri}
      className="border-red border-t-6 transition-all duration-300 hover:shadow-post"
    >
      <header
        className={`relative overflow-hidden ${
          size === `small` ? `h-225` : `h-320`
        }`}
      >
        <Img
          alt="Serction Highlight"
          fluid={post.featuredImage.node.localFile.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          className="w-full h-full absolute inset-0 postblock__img"
        />
        <div
          className={`absolute inset-0 ${
            size === `small`
              ? `bg-gradient-b-semi-black-transparent`
              : `bg-gradient-t-semi-black-transparent`
          }`}
        />
        {size === `large` && (
          <div className="absolute top-0 left-0 bg-red text-white text-17 md:text-19 uppercase px-25 py-6">
            Latest Post
          </div>
        )}
        <div
          className={`absolute inset-0 text-white px-15 py-20 ${
            size === `large` ? `flex flex-col justify-end` : ``
          }`}
        >
          <h3
            className={`text-19 font-normal leading-tight ${
              size === `large` ? `max-w-750 md:text-27 lg:text-32` : `max-w-355`
            }`}
            dangerouslySetInnerHTML={{ __html: post.title }}
          />
          <p className="text-17 pt-10">
            {/* {new Intl.DateTimeFormat(`en-UK`, {
              dateStyle: `long`,
            }).format(new Date(post.date))} */}
            {moment(post.date).format(`MMMM Do, YYYY`)}
          </p>
        </div>
      </header>
      <div className="flex justify-end items-center border-b-2 border-t-2 border-black py-4 text-15 font-bold px-18 bg-gray-200">
        <span className="inline-flex items-center postblock__link">
          <span className="block">View Details</span>
          <span className="block text-20 ml-15 mb-3">→</span>
        </span>
      </div>
    </StyledPostBlock>
  )
}

PostBlock.defaultProps = {
  size: `small`,
}

PostBlock.propTypes = {
  size: PropTypes.string,
  post: PropTypes.object.isRequired,
}

import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const StyledLinkLine = styled(Link)`
  display: inline-flex;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.3s ease;
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  &:hover::before {
    transform-origin: bottom right;
    transform: scaleX(0);
  }
`

export default function LinkLine(props) {
  return <StyledLinkLine {...props} />
}

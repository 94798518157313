import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { FaTimes } from "react-icons/fa";

import { SectionTitle } from '#components'

const StyledLink = styled.a`
  span {
    display: inline-flex;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: currentColor;
      transition: transform 0.3s ease;
      transform-origin: bottom right;
      transform: scaleX(0);
    }
  }

  &:hover span::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }
`

function Team({ title, teamMembers }) {
  const [view, setView] = useState(null)
  const [modalVisible, setModalVisible] = useState(false);
  const [activeVid, setActiveVid] = useState('');

  function showModal(item) {
    setActiveVid(item);
    setModalVisible(true);
  }

  return (
    <>
      <div className="pt-60 pb-60 relative bg-white">
        <div className="container-md">
          <SectionTitle title={title} />

          <div className="flex flex-wrap -mx-20 pt-40">
            {teamMembers &&
              teamMembers.map((member, index) => (
                <>
                  {index == 2 ? (
                    <div className="px-20 hidden xl:block w-1/3 mb-40">
                      &nbsp;
                    </div>
                  ) : null}
                  <div
                    className="px-20 w-full md:w-1/2 xl:w-1/3 mb-40"
                    key={member.email}
                  >
                    <div className="transition-all duration-300 hover:shadow-post h-full flex flex-col">
                      <button
                        type="button"
                        onClick={() => {
                          if (view === index) {
                            setView(null);
                          } else {
                            setView(index);
                          }
                        }}
                        className={`block border border-gray-750 border-solid h-210 w-full relative overflow-hidden team__top ${
                          view === index ? `is-active` : ``
                        }`}
                      >
                        <div className="absolute inset-0">
                          <Img
                            alt="Serction Highlight"
                            fluid={member.image.localFile.childImageSharp.fluid}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            className="w-full h-full absolute inset-0 postblock__img"
                          />
                        </div>
                        <div className="overflow-y-scroll text-left absolute inset-0 bg-white py-25 px-12 border-t-2 border-gray-750 team__content">
                          <div
                            className="text-15 2xl:text-16 text-black"
                            dangerouslySetInnerHTML={{ __html: member.bio }}
                          />
                        </div>
                      </button>

                      <div className="px-12 flex flex-col bg-white pt-16 pb-20 border-b border-gray-750 flex-grow">
                        <p
                          className="leading-tight text-20 font-bold w-full"
                          dangerouslySetInnerHTML={{ __html: member.fullName }}
                        />
                        <p
                          className="leading-tight text-13 font-medium w-full"
                          dangerouslySetInnerHTML={{ __html: member.title }}
                        />

                        <div className="text-13 font-medium pt-20 mt-auto w-full">
                          <div>
                            <StyledLink
                              className="text-13 font-medium"
                              href={`tel:${member.tel}`}
                            >
                              <strong className="font-bold">Tel:</strong>
                              {` `}
                              <span>{member.tel}</span>
                            </StyledLink>
                          </div>
                          <div>
                            <StyledLink
                              className="text-13 font-medium"
                              href={`mailto:${member.email}`}
                            >
                              <strong className="font-bold">Email:</strong>
                              {` `}
                              <span>{member.email}</span>
                            </StyledLink>
                          </div>
                          <div className="mt-20 flex items-center">
                            {member.linkedin && (
                              <a
                                href={member.linkedin}
                                target="_blank"
                                class="inline-block mr-15"
                                style={{ width: "42px", height: "42px" }}
                              >
                                <img
                                  src="/images/linkedin.png"
                                  alt="LinkedIn"
                                  width="42"
                                  height="42"
                                />
                              </a>
                            )}
                            {member.video && (
                              <button
                                type="button"
                                class="inline-block"
                                onClick={() => showModal(member.video)}
                                style={{ width: "42px", height: "42px" }}
                              >
                                <img
                                  src="/images/play.png"
                                  alt="LinkedIn"
                                  width="42"
                                  height="42"
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-0 z-100 transition-all duration-300 flex justify-center items-center ${
          modalVisible ? `opacity-100 visible` : `opacity-0 invisible`
          }`}
        style={{ zIndex: '100' }}
      >
        <button
          type="button"
          className="absolute inset-0 bg-black opacity-90 text-transparent text-0"
          style={{ opacity: '0.9' }}
          onClick={() => setModalVisible(false)}
        >
          Close Modal
        </button>
        <div className="max-w-full px-20 relative" style={{ width: '980px' }}>
          {modalVisible ? (
            <div
              className="c-resembed"
              dangerouslySetInnerHTML={{ __html: activeVid }}
            />
          ) : null}
        </div>
        <button
          onClick={() => setModalVisible(false)}
          type="button"
          className="absolute right-30 top-30 w-40 h-40 flex items-center justify-center text-white text-24"
          style={{ top: '30px', right: '30px' }}
        >
          <FaTimes />
        </button>
      </div>
    </>
  );
}

Team.propTypes = {
  title: PropTypes.string,
  teamMembers: PropTypes.array,
}

export default Team

import React from 'react'
import PropTypes from 'prop-types'
import {
  /* PLOP_INJECT_IMPORT */
  ContactSection,
  HeroSection,
  CalloutBar,
  CalloutNew,
  FeaturedBlock,
  FeaturedLots,
  FeaturedOnlineLots,
  LatestBlogPosts,
  FeaturedMessage,
  Team,
  Testimonial,
  FeaturedLinks,
  FeaturedDownloads,
  ContentArea,
  PageIntro,
  NewsletterForm,
  FormSection,
  AboutUs,
  NewIntro,
  NewBenefits,
  NewFeaturedBlocks,
} from '#sections'

export default function AllSections({ sectionData }) {
  const sectionType = sectionData.fieldGroupName

  const Default = () => (
    <div>
      In AllSections the mapping of this component is missing: {sectionType}
    </div>
  )

  const sections = {
    /* PLOP_INJECT_SECTION */
    page_Builder_Sections_ContactSection: ContactSection,
    page_Builder_Sections_HeroSection: HeroSection,
    page_Builder_Sections_CalloutBar: CalloutBar,
    page_Builder_Sections_CalloutNew: CalloutNew,
    page_Builder_Sections_FeaturedBlock: FeaturedBlock,
    page_Builder_Sections_FeaturedLots: FeaturedLots,
    page_Builder_Sections_FeaturedOnlineLots: FeaturedOnlineLots,
    page_Builder_Sections_LatestBlogPosts: LatestBlogPosts,
    page_Builder_Sections_FeaturedMessage: FeaturedMessage,
    page_Builder_Sections_Team: Team,
    page_Builder_Sections_Testimonial: Testimonial,
    page_Builder_Sections_FeaturedLinks: FeaturedLinks,
    page_Builder_Sections_FeaturedDownloads: FeaturedDownloads,
    page_Builder_Sections_ContentArea: ContentArea,
    page_Builder_Sections_PageIntro: PageIntro,
    page_Builder_Sections_NewsletterForm: NewsletterForm,
    page_Builder_Sections_FormSection: FormSection,
    page_Builder_Sections_AboutUs: AboutUs,
    page_Builder_Sections_NewIntro: NewIntro,
    page_Builder_Sections_NewBenefits: NewBenefits,
    page_Builder_Sections_NewFeaturedBlocks: NewFeaturedBlocks,
    page_default: Default,
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = sections[sectionType]
    ? sections[sectionType]
    : sections.page_default

  return <ComponentTag {...sectionData} />
}

AllSections.propTypes = {
  sectionData: PropTypes.object,
}

import React from 'react'
import PropTypes from 'prop-types'
import {
  GoogleMap as ReactGoogleMaps,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'
import { FaTwitter, FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { navigate } from 'gatsby'

import mapOptions from '../../templates/eig/mapOptions'
import { SectionTitle, GravityForm } from '#components'
import { useThemeOptions, useGravityData } from '#hooks'

function ContactSection({
  title,
  formTitle,
  formId,
  sidebarTitle,
  mapLocation,
}) {
  const {
    phoneNumber,
    fax,
    email,
    address,
    facebookProfileUrl,
    twitterProfileUrl,
    linkedinProfileUrl,
  } = useThemeOptions()
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `AIzaSyAbhJwdrGNW8pJSqJ29tMS0hWJ0syTakBw`,
  })

  const formData = useGravityData()

  function handleError({ values, error, reset }) {
    console.log(values)
    console.log(error)
  }

  function handleSuccess({ values, reset, confirmations }) {
    // handle success
    navigate(`/thank-you/`)
  }

  const renderMap = () => (
    <ReactGoogleMaps
      zoom={15}
      mapContainerStyle={{
        height: `550px`,
        width: `100%`,
      }}
      center={{
        lat: parseFloat(mapLocation.latitude),
        lng: parseFloat(mapLocation.longitude),
      }}
      options={mapOptions}
    >
      <Marker
        position={{
          lat: parseFloat(mapLocation.latitude),
          lng: parseFloat(mapLocation.longitude),
        }}
        icon="/images/marker.png"
      />
    </ReactGoogleMaps>
  )

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }
  return (
    <div className="bg-gray-200 relative">
      <div className="container pt-55 pb-60 md:pb-80">
        <SectionTitle title={title} />
        <div className="flex flex-wrap pt-40 md:pt-55">
          <div className="w-full md:w-2/3">
            <h3
              className="text-24 md:text-27 xl:text-33 text-red font-bold mb-20"
              dangerouslySetInnerHTML={{ __html: formTitle }}
            />
            <div className="c-gravityform">
              <GravityForm
                id={parseInt(formId)}
                formData={formData}
                lambda={`https://admin.auctionhouselondon.co.uk/wp-json/llgf/v1/form/${formId}`}
                successCallback={handleSuccess}
                errorCallback={handleError}
              />
            </div>
          </div>
          <div className="w-full md:w-1/3 md:pl-30 xl:pl-60 pt-40 md:pt-0">
            <h3
              className="text-24 md:text-27 xl:text-33 text-red font-bold mb-20"
              dangerouslySetInnerHTML={{ __html: sidebarTitle }}
            />
            <div className="text-16 xl:text-19">
              <p className="font-bold">Auction House London</p>
              <p dangerouslySetInnerHTML={{ __html: address }} />

              <div className="mt-15">
                <p>
                  Tel:{` `}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline no-underline"
                    href={`tel:${phoneNumber}`}
                  >
                    {phoneNumber}
                  </a>
                </p>
                <p>Fax: {fax}</p>
                <p>
                  Email:{` `}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline no-underline"
                    href={`mailto:${email}`}
                  >
                    {email}
                  </a>
                </p>
              </div>
            </div>
            <div className="flex items-center mt-25">
              <a
                className="transition-all duration-300 text-white w-30 h-30 lg:w-45 lg:h-45 rounded-full flex items-center justify-center text-15 lg:text-22 bg-black hover:bg-facebook"
                href={facebookProfileUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF />
              </a>
              <a
                className="ml-12 transition-all duration-300 text-white w-30 h-30 lg:w-45 lg:h-45 rounded-full flex items-center justify-center text-15 lg:text-22 bg-black hover:bg-twitter"
                href={twitterProfileUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter />
              </a>
              <a
                className="ml-12 transition-all duration-300 text-white w-30 h-30 lg:w-45 lg:h-45 rounded-full flex items-center justify-center text-15 lg:text-22 bg-black hover:bg-linkedin"
                href={linkedinProfileUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="h-550">{isLoaded ? renderMap() : null}</div>
    </div>
  )
}

ContactSection.propTypes = {
  title: PropTypes.string,
  formTitle: PropTypes.string,
  formId: PropTypes.string,
  sidebarTitle: PropTypes.string,
  mapLocation: PropTypes.object,
}

export default ContactSection

import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const StyledArrowButton = styled.button`
  display: inline-flex;
  outline: none;
  position: relative;
  font-size: 20px;
  min-width: 220px;
  padding-left: 20px;
  padding-right: 80px;
  text-transform: uppercase;
  line-height: 1;
  border: 2px solid
    ${(props) =>
      props.variant === `accent`
        ? `#c8102e`
        : props.variant === `green`
        ? `#9c0`
        : `#fff`};
  background: ${(props) =>
    props.variant === `accent`
      ? `#c8102e`
      : props.variant === `green`
      ? `#9c0`
      : `transparent`};
  text-align: left;
  padding-top: 14px;
  padding-bottom: 12px;
  overflow: hidden;
  color: #fff;

  span {
    transition: all 0.3s;
  }

  &::before,
  &::after {
    content: '→';
    position: absolute;
    top: 50%;
    transition: all 0.3s;
  }

  &::after {
    right: 20px;
    transform: translateY(-50%);
  }

  &::before {
    left: 20px;
    transform: translateY(-50%) translateX(-70px);
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    span {
      transform: translateX(40px);
    }

    &::after {
      transform: translateY(-50%) translateX(70px);
    }

    &::before {
      transform: translateY(-50%);
    }
  }

  @media (min-width: 768px) {
    font-size: 24px;
    min-width: 270px;
  }
`

export default function ArrowButton({
  to,
  target,
  variant,
  children,
  ...otherProps
}) {
  return (
    <>
      {to ? (
        <StyledArrowButton
          variant={variant}
          to={to}
          target={target}
          as={Link}
          {...otherProps}
        >
          <span>{children}</span>
        </StyledArrowButton>
      ) : (
        <StyledArrowButton variant={variant} {...otherProps}>
          <span>{children}</span>
        </StyledArrowButton>
      )}
    </>
  )
}

ArrowButton.defaultProps = {
  variant: `accent`,
}

ArrowButton.propTypes = {
  variant: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import styled from "@emotion/styled";

const StyledSection = styled.div`
  .fb-container {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;

    .content-wrapper {
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;

      @media (min-width: 768px) {
        padding-left: 30px;
        padding-right: 30px;
        width: 50%;
        order: -1;
      }

      @media (min-width: 1024px) {
        padding-left: 40px;
        padding-right: 40px;
      }

      @media (min-width: 1180px) {
        padding-left: 50px;
        padding-right: 50px;
      }
    }

    .content-inner {
      max-width: 650px;
      margin-right: auto;

      @media (min-width: 768px) {
        margin-right: 0;
        margin-left: auto;
      }

      @media (min-width: 1024px) {
        min-height: 570px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      h2 {
        font-weight: bold;
        font-size: 19px;
        line-height: 1.5;

        @media (min-width: 768px) {
          font-size: 26px;
        }
      }

      .content {
        margin-top: 30px;
        font-size: 18px;
        line-height: 1.5;
        font-weight: 300;

        @media (min-width: 768px) {
          font-size: 22px;
        }

        p {
          + p {
            margin-top: 20px;
          }
        }
      }
    }

    .image-wrapper {
      position: relative;
      width: 100%;
      height: 240px;

      > div {
        position: absolute !important;
        top: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        left: 0 !important;
      }

      @media (min-width: 768px) {
        width: 50%;
        height: auto;
      }
    }

    &.fb-container--right {
      .content-inner {
        @media (min-width: 768px) {
          margin-right: auto !important;
          margin-left: 0 !important;
        }
      }

      .content-wrapper {
        @media (min-width: 768px) {
          order: 1 !important;
        }
      }
    }
  }
`;

function NewFeaturedBlocks({ title, content, imageField, imageRightSide }) {
  return (
    <StyledSection>
      <div className="bg-white relative wrapper">
        <div
          className={`fb-container ${
            imageRightSide ? `fb-container--right` : ``
          }`}
        >
          <div className="image-wrapper">
            <Img
              alt="Serction Highlight"
              fluid={imageField.localFile.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              className="w-full h-full absolute inset-0"
            />
          </div>
          <div className="content-wrapper">
            <div className="content-inner">
              {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
              {content && (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledSection>
  );
}

NewFeaturedBlocks.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default NewFeaturedBlocks;

import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

export default function PageIntro({
  preview,
  backgroundImage,
  title,
  description,
}) {
  return (
    <div
      className={`relative overflow-hidden ${
        title === `Current Lots` ? `h-270 md:h-340` : `h-240 md:h-340`
      }`}
    >
      <div
        className={`absolute inset-x-0 top-0 ${
          title === `Current Lots` ? `h-270 md:h-340` : `h-240 md:h-340`
        }`}
      >
        {preview ? (
          <img
            src={backgroundImage.node.mediaItemUrl}
            alt="Section Highlight"
            className="w-full h-full absolute inset-0 object-cover object-center"
          />
        ) : (
          <img
            src={
              backgroundImage.node
                ? backgroundImage.node.localFile.childImageSharp.fluid.src
                : backgroundImage.localFile.childImageSharp.fluid.src
            }
            alt="Section Highlight"
            className="w-full h-full absolute inset-0 object-cover object-center"
          />
        )}
        <div className="absolute inset-0 bg-ablack-200" />
        <div className="absolute inset-0 bg-gradient-br-semi-black-transparent" />
      </div>
      {(title || description) && (
        <div className="text-center container relative h-full flex flex-wrap flex-col items-center justify-center pt-70">
          {title && (
            <h1
              className="text-white font-bold text-27 md:text-32 lg:text-42 xl:text-51 w-full leading-none"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {description && (
            <div
              className="font-light text-white uppercase text-18 md:text-20 lg:text-24 w-full leading-tight pt-6"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      )}
    </div>
  )
}

PageIntro.defaultProps = {
  preview: false,
}

PageIntro.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  preview: PropTypes.any,
  backgroundImage: PropTypes.object.isRequired,
}

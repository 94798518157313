import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const StyledContentSection = styled.div`
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;

  a {
    color: #c8102e;
    text-decoration: underline;
    font-weight: 700;

    &:hover {
      text-decoration: none;
    }
  }

  h2 {
    margin-bottom: 1em;
    text-transform: uppercase;
    font-weight: 700;
  }

  @media (min-width: 768px) {
    font-size: 22px;
  }

  @media (min-width: 1024px) {
    font-size: 25px;
  }

  @media (min-width: 1280px) {
    font-size: 28px;
  }

  p {
    margin-bottom: 1em;
  }

  b,
  strong {
    font-weight: 700;
  }
`

export default function ContentSection({ content }) {
  return <StyledContentSection dangerouslySetInnerHTML={{ __html: content }} />
}

ContentSection.propTypes = {
  content: PropTypes.string.isRequired,
}

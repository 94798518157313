import React from 'react'
import PropTypes from 'prop-types'

import { LinkLine } from '#components'

export default function SectionTitle({
  title,
  subtitle,
  mainLink,
  links,
  green,
}) {
  return (
    <h2 className="flex items-center">
      <span
        className={`self-start block h-34 w-8 mr-25 mb-5 ${
          green ? `bg-green` : `bg-red`
        }`}
      />
      {mainLink ? (
        <span className="font-bold leading-tight text-20 md:text-24 lg:text-28 xl:text-32">
          <LinkLine to={mainLink}>{title}</LinkLine>
        </span>
      ) : (
        <span
          className="font-bold leading-tight text-20 md:text-24 lg:text-28 xl:text-32"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}

      {!!subtitle && (
        <span
          className="text-15 md:text-17 lg:text-19 pb-7 lg:pb-2 uppercase ml-10 md:ml-15 self-end"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}

      {!!links &&
        links.map((link, index) => (
          <span
            key={link.uri}
            className="font-bold leading-tight text-20 md:text-24 lg:text-28 xl:text-32"
          >
            {index === 0 ? (
              <span className="mx-10 sm:mx-20">/</span>
            ) : (
              <span>, </span>
            )}
            <LinkLine to={link.uri}>{link.name}</LinkLine>
          </span>
        ))}
    </h2>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  mainLink: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.object),
  green: PropTypes.bool,
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { SectionTitle } from '#components'

const StyledSection = styled.div`
  .c-container {
    max-width: 1085px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .b-button {
    font-size: 15px;
    font-weight: 700;
    background: #d90025;
    color: #fff;
    display: inline-block;
    line-height: 40px;
    min-width: 130px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 20px;
    text-align: center;
    transition: all 0.3s;
    outline: none;

    &:hover {
      background: #a70f28;
    }
  }

  .c-content {
    max-width: 800px;
    font-size: 18px;

    p {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      li {
        display: block;
        position: relative;
        padding-left: 20px;
        margin-bottom: 15px;

        &::before {
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          top: 0.6em;
          left: 0;
          background: #000;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.is-highlight {
        li {
          color: #d90025;
          font-weight: 700;

          @media (min-width: 768px) {
            font-size: 20px;
          }

          @media (min-width: 1024px) {
            font-size: 24px;
          }

          &::before {
            background: #d90025;
          }
        }
      }
    }

    a {
      text-decoration: underline;
      font-weight: 700;

      &:hover {
        text-decoration: none;
      }
    }

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  .c-icon {
    margin-bottom: 25px;
    display: inline-block;

    @media (min-width: 768px) {
      display: block;
      margin-bottom: 0;
      width: 109px;
    }
  }

  .c-title {
    @media (min-width: 768px) {
      margin-left: -36px;
    }
  }

  .c-feature {
    margin-bottom: 50px;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
      margin-bottom: 50px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .c-content {
      max-width: 780px;
    }
  }
`

function FeaturedBlock({
  title,
  button,
  content,
  bottomButton,
  features,
  showSidebar,
}) {
  return (
    <div className="relative py-60">
      <StyledSection>
        <div className={`${showSidebar ? `container` : `c-container`}`}>
          <div className="relative">
            {title && (
              <div className="c-title mb-40">
                <SectionTitle title={title} />
              </div>
            )}

            <div
              className="c-content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            {button && (
              <div className="mt-30 lg:mt-0 lg:absolute lg:right-0 lg:top-0">
                <a href={button.url} className="b-button">
                  {button.title}
                </a>
              </div>
            )}
          </div>
          {features && (
            <div className="pt-30 md:pt-60">
              {features.map((item) => (
                <div
                  className="relative flex justify-center md:justify-start flex-wrap md:flex-no-wrap c-feature lg:pr-210"
                  key={item.icon.sourceUrl}
                >
                  <div className="c-icon flex-shrink-0">
                    <img src={item.icon.sourceUrl} alt="Icon" />
                  </div>
                  <div className="flex-grow">
                    <div
                      className="c-content"
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                  </div>
                  {item.button && (
                    <div className="mt-20 lg:mt-0 lg:absolute lg:right-0 lg:top-0">
                      <a href={item.button.url} className="b-button">
                        {item.button.title}
                      </a>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {bottomButton && (
            <div className="pt-30 md:pt-50 md:pb-30">
              <a href={bottomButton.url} className="b-button">
                {bottomButton.title}
              </a>
            </div>
          )}
        </div>
      </StyledSection>
    </div>
  )
}

FeaturedBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  button: PropTypes.object,
  bottomButton: PropTypes.object,
  features: PropTypes.array,
  showSidebar: PropTypes.bool,
}

export default FeaturedBlock

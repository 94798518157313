import React from 'react'
import BarLoader from 'react-spinners/BarLoader'

function Loader() {
  return (
    <div className="fixed inset-0 z-50 bg-white flex items-center justify-center text-center">
      <BarLoader height={10} width={400} color="#c8102e" />
    </div>
  )
}

export default Loader

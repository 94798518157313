import React from 'react'
import PropTypes from 'prop-types'
import slugify from 'slugify'

import UniversalLink from '../UniversalLink'

function InfoWindow({ property, setActiveProperty }) {
  const image = property.Thumbnail.replace(
    `_enhanced_thumb`,
    `_web_medium`
  ).replace(`_thumb`, `_web_medium`)
  const slug = `/lot/${slugify(property.FullAddress, {
    lower: true,
    strict: true,
  })}-${property.ID}/`

  return (
    <div className="w-285 h-330 absolute bottom-40p left-50p transform -translate-x-1/2 z-20">
      <div className="absolute inset-x-0 top-0 bottom-15 shadow-info" />
      <div className="absolute inset-0 c-infowindow">
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${image})` }}
        />
        <div className="c-propertyblock__overlay absolute inset-0" />
        {property.GuidePrice === `SOLD PRIOR` && (
          <span className="c-soldprior bg-red text-white absolute">
            {property.GuidePrice}
          </span>
        )}
      </div>
      <UniversalLink
        to={slug}
        className="pb-17 px-18 text-white flex flex-col c-propertyblock justify-end absolute inset-x-0 top-0 bottom-15 overflow-hidden"
      >
        <div className="c-propertyblock__content">
          <h3 className="text-17 lg:text-19 leading-snug mb-5">
            <span
              className="block text-20 font-normal leading-tight"
              dangerouslySetInnerHTML={{
                __html: property.FullAddress,
              }}
            />
          </h3>
          {property.ResultPrice ? (
            <div className="w-auto max-w-220">
              <p className="text-18 leading-snug text-white font-semibold">
                *{property.ResultPrice}
              </p>
            </div>
          ) : (
            <div className="flex-shrink-0">
              <p className="text-19 lg:text-23 text-white font-semibold">
                *{property.GuidePrice}
              </p>
            </div>
          )}
        </div>
      </UniversalLink>
      <button
        className="w-36 h-36 flex items-center justify-center bg-red absolute top-0 -left-36 transition-all duration-300 hover-bg-secondary"
        type="button"
        onClick={() => setActiveProperty(null)}
      >
        <img
          src="/images/infoclose.svg"
          width="18"
          height="18"
          alt="Close Info Window"
        />
      </button>
    </div>
  )
}

InfoWindow.propTypes = {
  property: PropTypes.object,
  setActiveProperty: PropTypes.func,
}

export default InfoWindow

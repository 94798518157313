import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

import { useGeneralSettings } from '#hooks'

const UniversalLink = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  getProps,
  ...otherProps
}) => {
  const { url: wordPressUrl } = useGeneralSettings()
  const newUri = to === `#` ? to : to.replace(wordPressUrl, ``)
  const internal = to && !/\.[0-9a-z]+$/i.test(to) && /^\/(?!\/)/.test(newUri)

  if (to && to === `#`) {
    return <div {...otherProps}>{children}</div>
  }

  if (internal) {
    return (
      <GatsbyLink
        to={newUri}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        getProps={getProps}
        {...otherProps}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={to} {...otherProps} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

UniversalLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  partiallyActive: PropTypes.bool,
  activeClassName: PropTypes.string,
  getProps: PropTypes.func,
}

export default UniversalLink

import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import queryString from 'query-string'

import { Loader } from '#components'

const withPreview = (args = { preview: false }) => (Component) => {
  const C = (props) => {
    const { location } = props
    const parsed = queryString.parse(location.search)
    const { nonce, preview, post } = parsed

    const id = parseInt(post, 10)

    const { loading, error, data } = useQuery(args.preview, {
      variables: { id, nonce },
      skip: !preview,
    })

    if (!preview) {
      return <Component preview={false} {...props} />
    }

    if (loading) return <Loader />
    if (error) return <p>Error: ${error.message}</p>

    return <Component preview={data} {...props} />
  }

  C.propTypes = {
    location: PropTypes.object,
  }

  return C
}

export default withPreview

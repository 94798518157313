import React from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";

const StyledSection = styled.div`
  .wrapper {
    padding-top: 50px;
    padding-bottom: 30px;

    @media (min-width: 768px) {
      padding-top: 100px;
      padding-bottom: 40px;
    }
  }

  .container {
    max-width: 1040px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    h2 {
      text-align: center;
      font-weight: bold;
      font-size: 19px;
      line-height: 1.5;

      @media (min-width: 768px) {
        font-size: 26px;
      }
    }

    .content {
      margin-top: 50px;
      font-size: 18px;
      line-height: 1.5;
      font-weight: 300;

      @media (min-width: 768px) {
        font-size: 22px;
      }

      p {
        + p {
          margin-top: 20px;

          @media (min-width: 768px) {
            margin-top: 40px;
          }
        }
      }
    }

    .benefits {
      margin-top: 50px;
      margin-left: -10px;
      margin-right: -10px;
      display: flex;
      flex-wrap: wrap;

      @media (min-width: 768px) {
        margin-top: 80px;
      }

      .benefit {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        width: 100%;
        text-align: left;

        @media (min-width: 768px) {
          width: 50%;
        }

        @media (min-width: 1024px) {
          width: 33.3333%;
        }

        .inner {
          background: #f5f6f7;
          padding: 30px 25px 40px;
          height: 100%;
          text-align: left;

          h3 {
            font-weight: bold;
            font-size: 18px;
            line-height: 1.3;
            text-align: left;
          }

          section {
            font-size: 14px;
            font-weight: 300;
            margin-top: 27px;
            text-align: left;

            p {
              + p {
                margin-top: 14px;
              }
            }
          }
        }
      }
    }
  }
`;

function NewBenefits({ title, content, contentBlocks }) {
  return (
    <StyledSection>
      <div className="bg-white relative wrapper">
        <div className="container">
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
          {content && (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}

          <div className="benefits">
            {contentBlocks?.map((block) => (
              <div className="benefit">
                <div className="inner">
                  <h3 dangerouslySetInnerHTML={{ __html: block.title }}></h3>
                  <section
                    dangerouslySetInnerHTML={{ __html: block.content }}
                  ></section>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </StyledSection>
  );
}

NewBenefits.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default NewBenefits;

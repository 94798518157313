import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

function FeaturedLinks({
  accentColor,
  backgroundColor,
  padding,
  style,
  links,
}) {
  const paddingClasses = padding === `small` ? `py-30 md:py-40 lg:py-60` : ``

  return (
    <div className="relative" style={{ backgroundColor }}>
      <div className={`container ${paddingClasses}`}>
        {style === `twocol` ? (
          <div className="flex flex-wrap -mx-10">
            {links.map(({ description, title, link, image }) => (
              <div className="px-10 w-full sm:w-1/2 mb-10 md:mb-20" key={title}>
                <Link
                  to={link.url}
                  className="flex justify-end flex-col items-start group h-full min-h-210 text-white px-30 py-30 relative overflow-hidden transition-all duration-300 hover:shadow-post"
                  style={{ backgroundColor: accentColor }}
                >
                  {image && (
                    <div className="absolute inset-0">
                      <Img
                        alt="Serction Highlight"
                        fluid={image.localFile.childImageSharp.fluid}
                        objectFit="cover"
                        objectPosition="50% 50%"
                        className="w-full h-full absolute inset-0 transition-transform duration-4000 group-hover:scale-105 transform"
                      />
                      <div className="absolute inset-0 bg-gradient-tr-semi-black-transparent" />
                      <div className="absolute inset-0 bg-ablack-500 opacity-0 transition-all duration-300 group-hover:opacity-50" />
                    </div>
                  )}
                  <div
                    className={`absolute inset-0 border-4 transform transition-transform duration-300 scale-105 group-hover:scale-100 ${
                      image
                        ? `border-${
                            accentColor === `#99cc00` ? `green` : `red`
                          }`
                        : `border-ablack-500`
                    }`}
                  />
                  <h3
                    className="text-24 md:text-27 lg:text-31 font-bold leading-none relative"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                  <div
                    className="text-18 md:text-19 leading-tight relative pt-3"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-wrap">test</div>
        )}
      </div>
    </div>
  )
}

FeaturedLinks.propTypes = {
  accentColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  padding: PropTypes.string,
  style: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.shape({
        title: PropTypes.string,
        target: PropTypes.string,
        url: PropTypes.string,
      }),
      image: PropTypes.object,
    })
  ),
}

export default FeaturedLinks

import classnames from 'classnames'
import get from 'lodash/get'
import React from 'react'

import Captcha from '../../components/Captcha'
import Html from '../../components/Html'
import Input from '../../components/Input'
import Multiselect from '../../components/Multiselect'
import Select from '../../components/Select'
import SelectorList from '../../components/SelectorList'
import Textarea from '../../components/Textarea'
import { filteredKeys } from '../../utils/helpers'
import { ifDefaultValue, islabelHidden } from '../../utils/inputSettings'
import InputWrapper from '../../components/InputWrapper'

const FieldBuilder = ({
  formData,
  presetValues = {},
  register,
  control,
  errors,
  setValue,
  watch,
  getValues,
}) => {
  const watchAllFields = watch()

  return (
    // Loop through fields and create
    formData.formFields.map((field) => {
      // Set the wrapper classes
      const {
        descriptionPlacement: fieldDescPlace,
        isRequired,
        subLabelPlacement: fieldSubLabelPlace,
        visibility,
      } = field

      const conditionals = JSON.parse(field.conditionalLogic)?.rules

      let isVisible = true

      if (conditionals) {
        const testCond = conditionals.filter((cond) =>
          cond.operator === `is`
            ? watchAllFields[`input_${cond.fieldId}`] === cond.value
            : !watchAllFields[`input_${cond.fieldId}`] ||
              watchAllFields[`input_${cond.fieldId}`] !== cond.value
        )

        isVisible = testCond.length === conditionals.length
      }

      const descriptionPlacement =
        fieldDescPlace || formData.descriptionPlacement

      const subLabelPlacement = fieldSubLabelPlace || formData.subLabelPlacement

      const fieldData = { ...field, descriptionPlacement }
      const inputWrapperClass = classnames(
        `gfield`,
        `gravityform__field`,
        `gravityform__field__${field.type}`,
        `gravityform__field--${field.size}`,
        field.cssClass,
        { 'field-required': field.isRequired },
        { 'hidden-label': islabelHidden(field.labelPlacement) },
        { gfield_contains_required: isRequired },
        { [`field_sublabel_${subLabelPlacement}`]: subLabelPlacement },
        `field_description_${descriptionPlacement}`,
        `gfield_visibility_${visibility}`,
        { hidden: !isVisible }
      )

      const wrapId = `field_${formData.formId}_${field.id}`

      // TODO: Should this match GF version "input_form.id_input.id"
      const inputName = `input_${field.id}`

      let errorKey = ``

      switch (field.type) {
        // Add note for unsupported captcha field
        case `captcha`:
          return (
            <Captcha
              captchaTheme={field.captchaTheme}
              errors={errors[`input_${field.id}`]}
              fieldData={fieldData}
              key={field.id}
              name={inputName}
              register={register}
              setValue={setValue}
              wrapClassName={inputWrapperClass}
            />
          )
        case `fileupload`:
          return (
            <InputWrapper
              errors={errors[inputName]}
              inputData={fieldData}
              labelFor={inputName}
              wrapClassName={inputWrapperClass}
              wrapId={wrapId}
            >
              <input
                type="file"
                name={inputName}
                id={inputName}
                ref={register}
              />
            </InputWrapper>
          )
        // Start with the standard fields
        case `text`:
        case `number`:
        case `email`:
        case `hidden`:
        case `phone`:
          return (
            <Input
              errors={errors[inputName]}
              fieldData={fieldData}
              key={field.id}
              name={inputName}
              register={register}
              value={
                get(presetValues, inputName, false)
                  ? get(presetValues, inputName, false)
                  : ifDefaultValue(field)
              }
              wrapClassName={inputWrapperClass}
              wrapId={wrapId}
            />
          )
        case `textarea`:
          return (
            <Textarea
              errors={errors[inputName]}
              fieldData={fieldData}
              key={field.id}
              name={inputName}
              register={register}
              wrapClassName={inputWrapperClass}
              wrapId={wrapId}
            />
          )
        case `select`:
          return (
            <Select
              errors={errors[inputName]}
              fieldData={fieldData}
              key={field.id}
              name={inputName}
              control={control}
              register={register}
              wrapClassName={inputWrapperClass}
              wrapId={wrapId}
            />
          )
        case `multiselect`:
          return (
            <Multiselect
              errors={errors[inputName]}
              fieldData={fieldData}
              key={field.id}
              name={inputName}
              register={register}
              wrapClassName={inputWrapperClass}
              wrapId={wrapId}
            />
          )
        case `radio`:
        case `checkbox`:
          errorKey = filteredKeys(errors, RegExp(`input_${field.id}_`))
          return (
            <SelectorList
              errors={errorKey.length > 0 ? errors[errorKey[0]] : null}
              fieldData={fieldData}
              key={field.id}
              name={inputName}
              register={register}
              wrapClassName={inputWrapperClass}
              wrapId={wrapId}
            />
          )
        case `html`:
          return (
            <Html
              fieldData={fieldData}
              key={field.id}
              name={inputName}
              wrapClassName={inputWrapperClass}
              wrapId={wrapId}
            />
          )

        default:
          return null
      }
    })
  )
}

export default FieldBuilder

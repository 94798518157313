const strings = {
  errors: {
    general: `There was a problem with your submission. Errors have been highlighted below.`,
    leastOneField: `At least one field must be filled out.`,
    unknownError: `An unknown error occured.`,
    required: `This field is required.`,
    pattern: `The format seems to be wrong.`,
    maxChar: {
      front: `Need more than`,
      back: `characters.`,
    },
  },
}

export default strings

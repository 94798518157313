import React from 'react'
import PropTypes from 'prop-types'

function FeaturedDownloads({ downloads }) {
  return (
    <div>
      <h2>placeholder</h2>
    </div>
  )
}

FeaturedDownloads.propTypes = {
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      file: PropTypes.shape({
        title: PropTypes.string,
        target: PropTypes.string,
        url: PropTypes.string,
      }),
    })
  ),
}

export default FeaturedDownloads

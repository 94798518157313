import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { SectionTitle, LotBlock } from '#components'

// function getFormDate(d) {
//   const date = new Date(d)
//   return `${date.toLocaleString(`en-UK`, {
//     month: `long`,
//   })} ${date.getDate().toLocaleString(`en-UK`)} ${date.toLocaleString(`en-UK`, {
//     year: `numeric`,
//   })}`
// }

function FeaturedLots({ title, button }) {
  const {
    allLotsJson: { nodes },
  } = useStaticQuery(graphql`
    query FeaturedLotsQuery {
      allLotsJson(filter: { IsShownOnWeb: { eq: true }, IsFeatured: { eq: true } }, limit: 18) {
        nodes {
          ID
          FullAddress
          Thumbnail
          LotNumber
          GuidePrice
          LegalDocumentUrl
        }
      }
    }
  `)

  // // // const slug = `/auction/${slugify(getFormDate(auctions[0].AuctionDate), {
  // // //   lower: true,
  // // //   strict: true,
  // // // })}/`
  const lots = nodes.sort(() => 0.5 - Math.random())

  const [page, setPage] = useState(0)
  const pages = Math.ceil(lots.length / 6)

  if (lots?.length < 1) return null

  return (
    <div className="bg-white relative pt-60 pb-40 md:py-90 lg:pt-120">
      <div className="container">
        <div className="flex flex-wrap items-center justify-between">
          <SectionTitle title={title} />

          <div className="w-full md:w-auto items-center flex flex-shrink-0 mt-20 md:mt-0">
            <Link
              className="text-17 md:text-19 bg-red text-white flex-grow sm:flex-grow-0 px-20 lg:px-35 pt-9 pb-7 inline-block text-center transition-all duration-300 hover:bg-r-700 lg:min-w-208"
              to="/current-auction/"
            >
              View all lots
            </Link>
            <Link
              className="text-17 md:text-19 bg-white text-red px-20 lg:px-30 pt-7 pb-5 border-2 border-red inline-block text-center transition-all duration-300 hover:bg-red hover:text-white lg:min-w-208 ml-8"
              to={button.url}
              target={button.target}
            >
              {button.title}
            </Link>
          </div>
        </div>
        <div className="pt-30 md:pt-50">
          <div className="flex flex-wrap -mx-8">
            {lots.slice(page * 6, page * 6 + 6).map((lot) => (
              <div className="w-full md:w-1/2 lg:w-1/3 px-8 mb-20 md:mb-40" key={lot.ID}>
                <LotBlock truncated lot={lot} />
              </div>
            ))}
            {!lots.length && (
              <div className="text-center py-65 text-27 font-semibold w-full">
                Sorry, no results found for your search query.
              </div>
            )}
          </div>

          {pages > 1 && (
            <div className="flex justify-center items-center">
              <button
                className={`text-20 group mr-14 transition-all duration-300 ${
                  page === 0 ? `opacity-0 invisible` : `opacity-100 visible`
                }`}
                type="button"
                onClick={() => {
                  const to = page === 0 ? pages - 1 : page - 1

                  setPage(to)
                }}
              >
                <span className="inline-block transform transition-transform duration-300 group-hover:-translate-x-3">
                  ←
                </span>
              </button>
              {[...Array(pages)].map((_, index) => (
                <button
                  onClick={() => setPage(index)}
                  type="button"
                  key={index}
                  className={`border-2 border-red w-20 h-20 mx-4 inline-block transition-all duration-300 color-transparent text-0 ${
                    index === page ? `bg-red` : `bg-white`
                  }`}
                >
                  Go to {index}
                </button>
              ))}
              <button
                className={`text-20 group ml-14 transition-all duration-300 ${
                  page === pages - 1 ? `opacity-0 invisible` : `opacity-100 visible`
                }`}
                type="button"
                onClick={() => {
                  const to = page === pages - 1 ? 0 : page + 1

                  setPage(to)
                }}
              >
                <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-3">
                  →
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

FeaturedLots.propTypes = {
  title: PropTypes.string,
  button: PropTypes.object,
}

export default FeaturedLots

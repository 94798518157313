import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { Header, Footer } from '#components'

const homeJsonLdData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Auction House London",
  "url": "https://auctionhouselondon.co.uk/",
  "logo": "https://auctionhouselondon.co.uk/images/logo.png",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+44-2076259007",
    "contactType": "Customer Service",
    "areaServed": "GB",
    "availableLanguage": "English"
  },
  "sameAs": [
    "https://www.facebook.com/PropertyAuctions/",
    "https://x.com/AuctionHouseLDN",
    "https://www.linkedin.com/company/1702545"
  ],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "5 Hampstead Gate, 1A Frognal, Hampstead",
    "addressLocality": "London",
    "postalCode": "NW3 6AL",
    "addressCountry": "GB"
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "09:30",
    "closes": "18:00"
  },
  "description": "Auction House London is a leading property auctioneer in London, offering a wide range of residential and commercial properties for sale through auctions.",
  "logo": "https://auctionhouselondon.co.uk/images/logo.png",
  "email": "info@auctionhouselondon.co.uk",
  "telephone": "+44-2076259007"
};

export default function Layout({ children, fixedHeader, title }) {
  return (
    <main className="bg-white relative min-h-full font-sans">
      <Helmet
        titleTemplate={`${
          title === `Homepage` ? `%s` : `%s | Auction House London`
        }`}
      >
        <title>
          {title === `Homepage`
            ? `London Property Auctions Residential & Commercial Auction House London`
            : title}
        </title>
        <meta
          name="facebook-domain-verification"
          content="oxqv5v8reemsnh5eug9f2820xy87xc"
        />
        {title === 'Homepage' && (
          <script type="application/ld+json">
            {JSON.stringify(homeJsonLdData)}
          </script>
        )}
      </Helmet>
      <Header fixedHeader={fixedHeader} />
      {children}
      <Footer />
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fixedHeader: PropTypes.bool,
  title: PropTypes.string,
}

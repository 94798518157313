import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { ArrowButton } from '#components'

function FeaturedMessage({ title, button, backgroundImage }) {
  return (
    <div className="h-screen relative flex justify-center items-center">
      <div className="absolute inset-0">
        <Img
          alt="Serction Highlight"
          fluid={backgroundImage.localFile.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          className="w-full h-full absolute inset-0"
        />
      </div>
      <div className="absolute opacity-65 inset-0 bg-black" />
      <div className="container w-full relative text-white pt-60">
        <h1
          className="font-bold text-32 md:text-37 lg:text-46 xl:text-64 mb-20"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <ArrowButton to={button.url} target={button.target} variant="accent">
          {button.title}
        </ArrowButton>
      </div>
    </div>
  )
}

FeaturedMessage.propTypes = {
  title: PropTypes.string,
  button: PropTypes.object,
  backgroundImage: PropTypes.object,
}

export default FeaturedMessage

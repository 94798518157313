import { useStaticQuery, graphql } from 'gatsby'

export default function useThemOptions() {
  const { wp } = useStaticQuery(
    graphql`
      query ThemeOptions {
        wp {
          themeOptions {
            themeOptions {
              mainNavigation {
                link {
                  url
                  title
                }
                subLinks {
                  link {
                    url
                    title
                  }
                }
              }
              auctionsBackgroundImage {
                localFile {
                  ...HeroImage
                }
              }
              notFoundPageBackgroundImage {
                localFile {
                  ...HeroImage
                }
              }
              notFoundPageButton {
                target
                title
                url
              }
              notFoundPageDescription
              notFoundPageTitle
              phoneNumber
              sidebarLinkImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 700) {
                      tracedSVG
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      presentationWidth
                    }
                  }
                }
              }
              sidebarLink {
                target
                title
                url
              }
              enquiryFormNotice
              currentCatalogue {
                target
                title
                url
              }
              miniCatalogues {
                catalogue {
                  target
                  title
                  url
                }
              }
              showCatalogues
              showMiniCatalogues
              fax
              email
              address
              facebookProfileUrl
              twitterProfileUrl
              linkedinProfileUrl
              footerColumn1Title
              footerColumn2Title
              footerColumn3Title
              footerColumn4Title
              footerColumn1Links {
                link {
                  url
                  title
                  target
                }
              }
              footerColumn2Links {
                link {
                  url
                  title
                  target
                }
              }
              footerColumn3Links {
                link {
                  url
                  title
                  target
                }
              }
              footerColumn4Links {
                link {
                  url
                  title
                  target
                }
              }
              overlayColumn1Items {
                title
                links {
                  link {
                    title
                    url
                    target
                  }
                }
              }
              overlayColumn2Items {
                title
                links {
                  link {
                    title
                    url
                    target
                  }
                }
              }
              overlayColumn3Items {
                title
                links {
                  link {
                    title
                    url
                    target
                  }
                }
              }
              overlayColumn4Items {
                title
                links {
                  link {
                    title
                    url
                    target
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return wp.themeOptions.themeOptions
}

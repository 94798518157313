import { useStaticQuery, graphql } from 'gatsby'

export default function useGeneralSettings() {
  const { wp } = useStaticQuery(
    graphql`
      query GeneralSettings {
        wp {
          generalSettings {
            title
            description
            url
          }
        }
      }
    `
  )

  return wp.generalSettings
}

import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import TimePicker from 'rc-time-picker'
import strings from '../../utils/strings'
import InputWrapper from '../InputWrapper'

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale })
}

const Input = ({ errors, fieldData, name, register, value, ...wrapProps }) => {
  const {
    cssClass,
    inputMaskValue,
    isRequired,
    maxLength,
    placeholder,
    size,
    type,
  } = fieldData
  const regex = inputMaskValue ? new RegExp(inputMaskValue) : false
  const isDate = cssClass.includes(`is-date`)
  const isTime = cssClass.includes(`is-time`)
  const FORMAT = `dd/MM/yyyy`
  const [date, setDate] = useState(``)
  const [time, setTime] = useState(``)
  const [timeOpen, setTimeOpen] = useState(false)

  function handleScroll() {
    setTimeOpen(false)
  }

  function setOpen({ open }) {
    setTimeOpen(open)
  }

  useEffect(() => {
    if (timeOpen) {
      window.addEventListener(`scroll`, handleScroll)
    } else {
      window.removeEventListener(`scroll`, handleScroll)
    }

    return () => {
      window.removeEventListener(`scroll`, handleScroll)
    }
  }, [timeOpen])

  return (
    <InputWrapper
      errors={errors}
      inputData={fieldData}
      labelFor={name}
      {...wrapProps}
    >
      {isTime ? (
        <div className="c-timefield">
          <TimePicker
            showSecond={false}
            placeholder={placeholder}
            open={timeOpen}
            onOpen={setOpen}
            onClose={setOpen}
            onChange={(e) => setTime(e ? e.format(`HH:mm`) : ``)}
          />
          <input
            style={{ position: `absolute`, left: `-99999px` }}
            className={classnames(
              `gravityform__field__input`,
              `gravityform__field__input__${type}`,
              cssClass,
              size
            )}
            id={name}
            maxLength={maxLength || 524288} // 524288 = 512kb, avoids invalid prop type error if maxLength is undefined.
            name={name}
            placeholder={placeholder}
            type={type === `phone` ? `tel` : type}
            value={time}
            readOnly
          />
          <img src="/images/clock.svg" alt="Date" />
        </div>
      ) : isDate ? (
        <div className="c-datefield">
          <DayPickerInput
            dayPickerProps={{
              modifiers: {
                disabled: { before: new Date() },
              },
            }}
            inputProps={{ type: `text` }}
            formatDate={formatDate}
            format={FORMAT}
            parseDate={parseDate}
            placeholder={placeholder}
            onDayChange={(e) =>
              setDate(`${dateFnsFormat(new Date(e), FORMAT)}`)
            }
          />
          <input
            style={{ position: `absolute`, left: `-99999px` }}
            className={classnames(
              `gravityform__field__input`,
              `gravityform__field__input__${type}`,
              cssClass,
              size
            )}
            id={name}
            maxLength={maxLength || 524288} // 524288 = 512kb, avoids invalid prop type error if maxLength is undefined.
            name={name}
            placeholder={placeholder}
            type={type === `phone` ? `tel` : type}
            value={date}
            readOnly
          />
          <img src="/images/date.svg" alt="Date" />
        </div>
      ) : (
        <input
          aria-invalid={errors}
          aria-required={isRequired}
          className={classnames(
            `gravityform__field__input`,
            `gravityform__field__input__${type}`,
            cssClass,
            size
          )}
          defaultValue={value}
          id={name}
          maxLength={maxLength || 524288} // 524288 = 512kb, avoids invalid prop type error if maxLength is undefined.
          name={name}
          placeholder={placeholder}
          ref={register({
            required: isRequired && strings.errors.required,
            maxlength: {
              value: maxLength > 0 && maxLength,
              message:
                maxLength > 0 &&
                `${strings.errors.maxChar.front}  ${maxLength} ${strings.errors.maxChar.back}`,
            },
            pattern: {
              value: regex,
              message: regex && strings.errors.pattern,
            },
          })}
          type={type === `phone` ? `tel` : type}
        />
      )}
    </InputWrapper>
  )
}

export default Input

Input.propTypes = {
  errors: PropTypes.object,
  fieldData: PropTypes.shape({
    cssClass: PropTypes.string,
    inputMaskValue: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.string,
  }),
  name: PropTypes.string,
  register: PropTypes.func,
  value: PropTypes.string,
  wrapProps: PropTypes.object,
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import slugify from 'slugify'
import { Link } from 'gatsby'
import { google } from 'calendar-link'
import { BsDownload } from 'react-icons/bs'

const StyledOnlineLotBlock = styled.div`
  .postblock__img {
    transition: all 4s;
  }

  &:hover {
    .postblock__img {
      transform: scale(1.1);
    }
  }

  .postblock__link {
    span {
      &.postblock__linktitle {
        display: inline-flex;
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          bottom: 2px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: currentColor;
          transition: transform 0.3s ease;

          transform-origin: bottom right;
          transform: scaleX(0);
        }
      }

      &.postblock__linkarrow {
        transition: all 0.3s;
      }

      &.postblock__linkplus {
        position: relative;
        top: 2px;
        transition: all 1s;
      }
    }

    &:hover {
      span {
        &.postblock__linktitle {
          &::before {
            transform-origin: bottom left;
            transform: scaleX(1);
          }
        }

        &.postblock__linkarrow {
          transform: translateX(6px);
        }

        &.postblock__linkplus {
          transform: rotate(360deg);
        }
      }
    }
  }
`

export default function OnlineLotBlock({ lot, truncated, resultPrice }) {
  const image = lot.Thumbnail.replace(`_enhanced_thumb`, `_web_medium`).replace(
    `_thumb`,
    `_web_medium`
  )

  const slug = `https://ahlondon.eigonlineauctions.com/lot/details/${lot.ID}/`

  let currentBid = null
  let noPrice = null
  let soldStatus = null

  if (lot.SoldStatus === 0 || lot.SoldStatus === 1) {
    currentBid = ``
    noPrice = true
    soldStatus = lot.SoldStatus === 0 ? `Sold` : `Sold Prior`
  } else if (lot.CurrentPrice) {
    currentBid = `<strong>Current Bid:</strong> £${lot.CurrentPrice.toLocaleString()}`
    noPrice = false
  } else if (lot.StartingPrice) {
    if (lot.StartingPrice === -1) {
      currentBid = `<strong>Starting Price:</strong> TBA`
    } else {
      currentBid = `<strong>Starting Price:</strong> £${lot.StartingPrice.toLocaleString()}`
    }

    noPrice = false
  } else {
    currentBid = ``
    noPrice = false
  }

  const startDate = new Date(lot.StartDate)
  const endDate = new Date(lot.EndDate)

  const month = [
    `January`,
    `February`,
    `March`,
    `April`,
    `May`,
    `June`,
    `July`,
    `August`,
    `September`,
    `October`,
    `November`,
    `December`,
  ]

  return (
    <StyledOnlineLotBlock className="relative flex flex-col h-full w-full border-t-6 border-green transition-all duration-300 hover:shadow-post">
      <a
        target="_blank"
        rel="noreferrer"
        className="relative flex flex-col h-full w-full"
        href={slug}
      >
        <header className="h-225 md:h-300 relative overflow-hidden">
          <div
            className="absolute inset-0 postblock__img"
            style={{
              background: `url(${image}) no-repeat center / cover`,
            }}
          />
        </header>
        <div
          className={`pt-23 px-10 flex justify-between ${
            !truncated ? `pb-10` : `pb-20`
          }`}
        >
          <div>
            {!truncated ? (
              <h3
                className="text-17 lg:text-19 leading-snug font-bold"
                dangerouslySetInnerHTML={{ __html: lot.FullAddress }}
              />
            ) : (
              <h3 className="text-17 lg:text-19 leading-snug">
                <span
                  className="block font-semibold lg:text-23"
                  dangerouslySetInnerHTML={{
                    __html: lot.FullAddress.slice(
                      0,
                      lot.FullAddress.indexOf(`,`)
                    ),
                  }}
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: lot.FullAddress.slice(
                      lot.FullAddress.indexOf(`,`) + 1
                    ),
                  }}
                />
              </h3>
            )}
          </div>
          <div className="flex-shrink-0">
            <div className="pl-20 text-19 lg:text-23 text-green font-semibold">
              {noPrice ? soldStatus : `*${lot.GuidePrice}`}
            </div>
          </div>
        </div>
        {!truncated && (
          <div className="pb-17 px-10">
            <div
              className="text-17 lg:text-19 leading-snug"
              dangerouslySetInnerHTML={{ __html: lot.TagLine }}
            />
          </div>
        )}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={slug}
        className="block border-t-2 border-gray-900 py-10 px-10"
      >
        <div
          style={{
            display: noPrice ? `none` : `block`,
          }}
        >
          <strong>Start Date:</strong>
          {` `}
          {`${month[startDate.getMonth()]} ${startDate.getDate()}${
            [`st`, `nd`, `rd`][
              ((((startDate.getDate() + 90) % 100) - 10) % 10) - 1
            ] || `th`
          }, ${startDate.getFullYear()} - ${startDate.getHours()}:${startDate
            .getMinutes()
            .toLocaleString(`en-US`, {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}`}
          <br />
          <strong>End Date:</strong>
          {` `}
          {`${month[endDate.getMonth()]} ${endDate.getDate()}${
            [`st`, `nd`, `rd`][
              ((((endDate.getDate() + 90) % 100) - 10) % 10) - 1
            ] || `th`
          }, ${endDate.getFullYear()} - ${endDate.getHours()}:${endDate
            .getMinutes()
            .toLocaleString(`en-US`, {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}`}
          <br />
          <div dangerouslySetInnerHTML={{ __html: currentBid }} />
        </div>
      </a>
      <div className="flex justify-between items-center border-b-3 border-t-2 border-gray-900 py-4 text-15 font-bold pl-10 pr-18 bg-gray-200">
        <button
          type="button"
          className="inline-flex items-center postblock__link font-bold text-15"
        >
          <span className="block text-20 mr-10 mb-3 postblock__linkplus">
            +
          </span>
          <span className="block postblock__linktitle">Add Favourite</span>
        </button>
        <a
          target="_blank"
          rel="noreferrer"
          href={slug}
          className="inline-flex items-center postblock__link"
        >
          <span className="block postblock__linktitle">View Details</span>
          <span className="block text-20 ml-15 mb-3 postblock__linkarrow">
            →
          </span>
        </a>
      </div>
    </StyledOnlineLotBlock>
  )
}

OnlineLotBlock.propTypes = {
  lot: PropTypes.object,
  truncated: PropTypes.bool,
  resultPrice: PropTypes.bool,
}

import React from 'react'
import PropTypes from 'prop-types'

function CalloutNew({ title, content }) {
  return (
    <div className="nb-wrap">
      <div className="nb-bg"></div>
      <div className="nb-container">
        <div className="nb-content">
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <div className="nb-logos">
          <span>
            <img src="https://admin.auctionhouselondon.co.uk/img/logo1.png" alt="AHL" />
          </span>
          <span>
            <img src="https://admin.auctionhouselondon.co.uk/img/logo2.png" alt="Homes Under the Hammer" />
          </span>
        </div>
      </div>
    </div>
  )
}

CalloutNew.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}

export default CalloutNew

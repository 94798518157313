import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import slugify from 'slugify'
import { ics } from 'calendar-link'

import getFormattedDate from '../../utils/getFormattedDate'
import { UniversalLink } from '#components'

const StyledAuctionBlock = styled(Link)`
  .postblock__link {
    span {
      &.postblock__linktitle {
        display: inline-flex;
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          bottom: 2px;
          left: 0;
          right: 0;
          height: 1px;
          background-color: currentColor;
          transition: transform 0.3s ease;

          transform-origin: bottom right;
          transform: scaleX(0);
        }
      }

      &.postblock__linkarrow {
        transition: all 0.3s;
      }

      &.postblock__linkplus {
        position: relative;
        top: 2px;
        transition: all 1s;
      }
    }

    &:hover {
      span {
        &.postblock__linktitle {
          &::before {
            transform-origin: bottom left;
            transform: scaleX(1);
          }
        }

        &.postblock__linkarrow {
          transform: translateX(6px);
        }

        &.postblock__linkplus {
          transform: rotate(360deg);
        }
      }
    }
  }
`

function getFormDate(d) {
  const date = new Date(d)
  return `${date.toLocaleString(`en-UK`, {
    month: `long`,
  })} ${date.getDate().toLocaleString(`en-UK`)} ${date.toLocaleString(`en-UK`, {
    year: `numeric`,
  })}`
}

function AuctionBlock({ auction, featured, future }) {
  const slug = `/auction/${slugify(getFormDate(auction.AuctionDate), {
    lower: true,
    strict: true,
  })}/`

  const auctionDate = new Date(auction.AuctionDate)
  const formattedAuctionTime = `${auctionDate.getHours()}:${auctionDate.getMinutes() === 0 ? '0' : ''}${auctionDate.getMinutes()}`

  return (
    <StyledAuctionBlock
      to={future && !auction.Lots.length ? `#` : future && featured ? `/current-auction/` : slug}
      onClick={(e) => {
        if (future && !auction.Lots.length) {
          e.preventDefault()
        }
      }}
      className="block h-full border-t-6 border-red bg-white transition-all duration-300 hover:shadow-post"
    >
      {featured ? (
        <div className="-mx-8 flex flex-wrap">
          <div className="w-full px-8 md:w-1/3">
            <div className="px-15 relative pb-25 pt-65">
              <span className="absolute top-0 left-0 bg-red text-white px-16 text-16 md:text-19 uppercase leading-none pt-7 pb-10">
                {future ? `Upcoming Auction` : `Latest Results`}
              </span>
              <p className="text-15 font-normal">Auction Date</p>
              {auction.ID === 2535 ? (
                <p
                  className="text-24 leading-none font-bold md:text-33 lg:text-46"
                >
                  7-8<sup>th</sup> February 2024
                </p>
              ) : auction.ID === 2448 ? (
                <p
                  className="text-24 leading-none font-bold md:text-33 lg:text-46"
                >
                  17-18<sup>th</sup> April 2024
                </p>
              ) : (
                <p
                  className="text-24 leading-none font-bold md:text-33 lg:text-46"
                  dangerouslySetInnerHTML={{
                    __html: getFormattedDate(new Date(auction.AuctionDate)),
                  }}
                />
              )}
              {future ? (
                <>
                  <p className="text-15 font-normal mt-20">Time</p>
                  <p className="text-24 leading-none font-normal md:text-33 lg:text-46">{formattedAuctionTime}AM</p>
                </>
              ) : (
                <>
                  <p className="text-15 font-normal mt-10">Sale Rate</p>
                  <p className="text-24 leading-none font-normal md:text-33 lg:text-51">
                    {auction.PercentageSold}%
                  </p>
                  <p className="text-15 font-normal mt-10">Total Raised</p>
                  {auction.TotalRaised && (
                    <p className="text-24 leading-none font-normal md:text-33 lg:text-51">
                      £{auction.TotalRaised.toLocaleString()}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          {auction.Lots && auction.Lots[0] && auction.Lots[0].Thumbnail && (
            <div className="hidden md:block w-2/3 px-8">
              <div className="relative h-full w-full">
                <div
                  className="absolute inset-0"
                  style={{
                    background: `url(${auction.Lots[0].Thumbnail.replace(
                      `_enhanced_thumb`,
                      `_web_medium`
                    ).replace(`_thumb`, `_web_medium`)}) no-repeat center / cover`,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="px-15 relative pb-15 pt-20">
          <p className="text-15 font-normal">Auction Date</p>
          <p
            className="text-24 leading-tight font-bold md:text-27 lg:text-33"
            dangerouslySetInnerHTML={{
              __html: getFormattedDate(new Date(auction.AuctionDate)),
            }}
          />
          {future ? (
            <>
              <p className="text-15 font-normal mt-10">Time</p>
              <p className="text-24 leading-tight font-normal md:text-27 lg:text-33">{formattedAuctionTime}AM</p>
            </>
          ) : (
            <>
              <p className="text-15 font-normal mt-10">Sale Rate</p>
              <p className="text-24 leading-tight font-normal md:text-27 lg:text-33">
                {auction.PercentageSold}%
              </p>
              <p className="text-15 font-normal mt-10">Total Raised</p>
              <p className="text-24 leading-tight font-normal md:text-27 lg:text-33">
                £{auction.TotalRaised ? auction.TotalRaised?.toLocaleString(): ''}
              </p>
            </>
          )}
        </div>
      )}
      <div
        className={`flex items-center border-b-3 border-t-2 border-gray-900 text-15 font-bold pr-18 bg-gray-200 ${
          future ? `justify-between` : `justify-end`
        }`}
      >
        {future && (
          <object>
            <UniversalLink
              to="/request-auction-valuation/"
              className="inline-block bg-red text-white pl-15 pr-12 pt-4 pb-2 font-normal c-arrowlink transition-all duration-300 hover:pr-20"
            >
              Free Valuation
            </UniversalLink>
          </object>
        )}
        {future && !!auction.Lots.length && (
          <span className="inline-flex items-center postblock__link leading-tight">
            <span className="block postblock__linktitle">View Details</span>
            <span className="block text-20 ml-15 mb-3 postblock__linkarrow">→</span>
          </span>
        )}
        {future && !featured && (
          <object>
            <a
              href={ics({
                title: `AHL Auction`,
                start: auction.AuctionDate,
                end: auction.AuctionDate,
              })}
              target="_blank"
              rel="noreferrer"
              className="text-red inline-flex leading-tight items-center postblock__link font-bold text-15"
            >
              <span className="block text-20 mr-10 mb-3 postblock__linkplus">+</span>
              <span className="block postblock__linktitle">Add to Calendar</span>
            </a>
          </object>
        )}
      </div>
    </StyledAuctionBlock>
  )
}

AuctionBlock.propTypes = {
  auction: PropTypes.object.isRequired,
  featured: PropTypes.bool,
  future: PropTypes.bool,
}

export default AuctionBlock

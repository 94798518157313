import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { SectionTitle, OnlineLotBlock } from '#components'

// CurrentPrice
// SoldStatus

function FeaturedOnlineLots({ title, button }) {
  const {
    allOnlineCatalogueJson: { nodes: lots },
  } = useStaticQuery(graphql`
    query FeaturedOnlineLotsQuery {
      allOnlineCatalogueJson(
        filter: { IsShownOnWeb: { eq: true } }
        limit: 18
      ) {
        nodes {
          ID
          FullAddress
          Thumbnail
          GuidePrice
          StartDate
          EndDate
          StartingPrice
        }
      }
    }
  `)
  const [page, setPage] = useState(0)
  const pages = Math.ceil(lots.length / 6)

  return (
    <div className="bg-white relative pt-60 pb-40 md:py-90 lg:pt-120">
      <div className="container">
        <div className="flex flex-wrap items-center justify-between">
          <SectionTitle green title={title} />

          <div className="w-full md:w-auto mt-20 md:mt-0">
            <Link
              className="text-17 md:text-19 bg-green text-white px-20 md:px-35 pt-9 pb-7 inline-block text-center transition-all duration-300 hover:bg-g-700 md:min-w-215"
              to={button.url}
              target={button.target}
            >
              {button.title}
            </Link>
          </div>
        </div>
        <div className="pt-30 md:pt-50">
          <div className="flex flex-wrap -mx-8">
            {lots.slice(page * 6, page * 6 + 6).map((lot) => (
              <div
                className="w-full md:w-1/2 lg:w-1/3 px-8 mb-20 md:mb-40"
                key={lot.ID}
              >
                <OnlineLotBlock green online truncated lot={lot} />
              </div>
            ))}
            {!lots.length && (
              <div className="text-center py-65 text-27 font-semibold w-full">
                Sorry, no results found for your search query.
              </div>
            )}
          </div>

          {pages > 1 && (
            <div className="flex justify-center items-center">
              <button
                className={`text-20 group mr-14 transition-all duration-300 ${
                  page === 0 ? `opacity-0 invisible` : `opacity-100 visible`
                }`}
                type="button"
                onClick={() => {
                  const to = page === 0 ? pages - 1 : page - 1

                  setPage(to)
                }}
              >
                <span className="inline-block transform transition-transform duration-300 group-hover:-translate-x-3">
                  ←
                </span>
              </button>
              {[...Array(pages)].map((_, index) => (
                <button
                  onClick={() => setPage(index)}
                  type="button"
                  key={index}
                  className={`border-2 border-green w-20 h-20 mx-4 inline-block transition-all duration-300 color-transparent text-0 ${
                    index === page ? `bg-green` : `bg-white`
                  }`}
                >
                  Go to {index}
                </button>
              ))}
              <button
                className={`text-20 group ml-14 transition-all duration-300 ${
                  page === pages - 1
                    ? `opacity-0 invisible`
                    : `opacity-100 visible`
                }`}
                type="button"
                onClick={() => {
                  const to = page === pages - 1 ? 0 : page + 1

                  setPage(to)
                }}
              >
                <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-3">
                  →
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

FeaturedOnlineLots.propTypes = {
  title: PropTypes.string,
  button: PropTypes.object,
}

export default FeaturedOnlineLots

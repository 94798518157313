import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import { SectionTitle } from '#components'

function LatestBlogPosts({ title }) {
  const {
    allWpPost: { nodes: posts },
  } = useStaticQuery(graphql`
    query LatestPostsQuery {
      allWpPost(
        sort: {fields: date, order: DESC}
        limit: 4
      ) {
        nodes {
          title
          uri
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="bg-white relative pt-60 pb-40 md:py-90 lg:pt-120">
      <div className="container">
        <SectionTitle title={title} />

        <div className="pt-30 md:pt-40">
          <div className="flex flex-wrap -mx-10">
            {posts &&
              posts.map((post, index) => (
                <div
                  className="w-full md:w-1/2 px-10 mb-10 md:mb-20"
                  key={post.uri}
                >
                  <Link
                    to={post.uri}
                    className="relative min-h-210 w-full h-full flex flex-col justify-end bg-red text-white overflow-hidden px-30 py-25 group"
                  >
                    {index === 1 || index === 2 ? (
                      <>
                        <div className="absolute inset-0">
                          <Img
                            alt="Serction Highlight"
                            fluid={
                              post.featuredImage.node.localFile.childImageSharp
                                .fluid
                            }
                            objectFit="cover"
                            objectPosition="50% 50%"
                            className="w-full h-full absolute inset-0 postblock__img transition-transform duration-4000 group-hover:scale-105 transform"
                          />
                          <div className="absolute inset-0 bg-gradient-tr-semi-black-transparent" />
                        </div>
                        <div className="absolute inset-0 border-4 transform transition-transform duration-300 scale-105 group-hover:scale-100 border-red" />
                      </>
                    ) : (
                      <div className="absolute inset-0 border-4 transform transition-transform duration-300 scale-105 group-hover:scale-100 border-ablack-500" />
                    )}
                    <h3
                      className="text-18 md:text-22 xl:text-27 font-bold leading-tight relative max-w-450"
                      dangerouslySetInnerHTML={{ __html: post.title }}
                    />
                    <div
                      className="text-15 md:text-16 lg:text-18 pt-10 font-normal leading-normal relative"
                      dangerouslySetInnerHTML={{ __html: post.excerpt }}
                    />
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

LatestBlogPosts.propTypes = {
  title: PropTypes.string,
}

export default LatestBlogPosts

import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Squash as Hamburger } from 'hamburger-react'
import { useScrollYPosition } from 'react-use-scroll-position'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { FaTwitter, FaFacebookF, FaLinkedinIn } from 'react-icons/fa'

import { useThemeOptions } from '#hooks'

const StyledLink = styled(Link)`
  display: inline-flex;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.3s ease;
    transform-origin: bottom right;
    transform: scaleX(0);
  }

  &:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  &.is-active {
    &::before {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
`

const StyledALink = styled.a`
  display: inline-flex;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.3s ease;
    transform-origin: bottom right;
    transform: scaleX(0);
  }

  &:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }

  &.is-active {
    &::before {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
`

const StyledOverlayMenu = styled.div`
  .overlay-title {
    /* overflow: hidden; */
    position: relative;

    h3 {
      transform: translateY(100%);
      transition: opacity 0.5s, transform 0.5s;
      opacity: 0;
    }
  }

  .overlay-link {
    /* overflow: hidden; */
    position: relative;

    a {
      transform: translateY(100%);
      transition: opacity 0.5s, transform 0.5s;
      opacity: 0;
    }
  }

  .overlay-bottom {
    > div {
      opacity: 0;
      transform: translateY(40px);
      transition: all 0.5s;
    }
  }

  &.is-open {
    .overlay-bottom {
      > div {
        opacity: 1;
        transform: none;

        &:nth-of-type(1) {
          transition-delay: 0.7s;
        }

        &:nth-of-type(2) {
          transition-delay: 0.7s;
        }

        &:nth-of-type(4) {
          transition-delay: 0.7s;
        }
      }
    }

    .overlay-title {
      h3 {
        transform: none;
        transition-delay: 0.1s;
        opacity: 1;
      }
    }

    .overlay-link {
      a {
        transform: none;
        opacity: 1;
      }

      &:nth-of-type(1) {
        a {
          transition-delay: 0.2s;
        }
      }

      &:nth-of-type(2) {
        a {
          transition-delay: 0.3s;
        }
      }

      &:nth-of-type(3) {
        a {
          transition-delay: 0.4s;
        }
      }

      &:nth-of-type(4) {
        a {
          transition-delay: 0.5s;
        }
      }

      &:nth-of-type(5) {
        a {
          transition-delay: 0.6s;
        }
      }

      &:nth-of-type(6) {
        a {
          transition-delay: 0.7s;
        }
      }

      &:nth-of-type(7) {
        a {
          transition-delay: 0.8s;
        }
      }

      &:nth-of-type(8) {
        a {
          transition-delay: 0.9s;
        }
      }
    }
  }
`

function OverlayMenu({ isOpen, setOpen }) {
  const {
    overlayColumn1Items,
    overlayColumn2Items,
    overlayColumn3Items,
    overlayColumn4Items,
    phoneNumber,
    fax,
    email,
    address,
    facebookProfileUrl,
    twitterProfileUrl,
    linkedinProfileUrl,
  } = useThemeOptions()
  const scrollY = useScrollYPosition()

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    if (isOpen) {
      document.body.style.overflow = `hidden`
    } else {
      document.body.style.overflow = originalStyle
    }
    return () => (document.body.style.overflow = originalStyle)
  }, [isOpen])

  return (
    <StyledOverlayMenu
      className={`pt-100 flex flex-col justify-center fixed inset-0 bg-ablack-900 z-100 text-white transition-all duration-300 ${
        isOpen ? `visible opacity-100 is-open` : `invisible opacity-0`
      }`}
    >
      <div className="w-full overflow-y-scroll overflow-x-hidden">
        <div className="container-large">
          <div className="flex flex-wrap -mx-35 2xl:-mx-50">
            <div className="w-full sm:w-1/2 lg:w-1/4 px-35 2xl:px-50 sm:border-r-2 border-white">
              {overlayColumn1Items &&
                overlayColumn1Items.map((col) => (
                  <div key={col.title} className="mb-20 lg:mb-40 2xl:mb-70">
                    <div className="overlay-title">
                      <h3
                        className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed font-bold"
                        dangerouslySetInnerHTML={{ __html: col.title }}
                      />
                    </div>
                    <ul>
                      {col.links.map(({ link }) => (
                        <li className="block overlay-link" key={link.url}>
                          {link.url.charAt(0) === `/` ? (
                            <StyledLink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              to={link.url}
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          ) : (
                            <StyledALink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              href={link.url}
                              target="_blank"
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              {overlayColumn2Items &&
                overlayColumn2Items.map((col) => (
                  <div
                    key={col.title}
                    className="lg:hidden mb-20 lg:mb-40 2xl:mb-70"
                  >
                    <div className="overlay-title">
                      <h3
                        className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed font-bold"
                        dangerouslySetInnerHTML={{ __html: col.title }}
                      />
                    </div>
                    <ul>
                      {col.links.map(({ link }) => (
                        <li className="block overlay-link" key={link.url}>
                          {link.url.charAt(0) === `/` ? (
                            <StyledLink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              to={link.url}
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          ) : (
                            <StyledALink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              href={link.url}
                              target="_blank"
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
            <div className="hidden lg:block w-1/2 lg:w-1/4 px-35 2xl:px-50 border-r-2 border-white">
              {overlayColumn2Items &&
                overlayColumn2Items.map((col) => (
                  <div key={col.title} className="mb-20 lg:mb-40 2xl:mb-70">
                    <div className="overlay-title">
                      <h3
                        className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed font-bold"
                        dangerouslySetInnerHTML={{ __html: col.title }}
                      />
                    </div>
                    <ul>
                      {col.links.map(({ link }) => (
                        <li className="block overlay-link" key={link.url}>
                          {link.url.charAt(0) === `/` ? (
                            <StyledLink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              to={link.url}
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          ) : (
                            <StyledALink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              href={link.url}
                              target="_blank"
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/4 px-35 2xl:px-50 lg:border-r-2 border-white">
              {overlayColumn3Items &&
                overlayColumn3Items.map((col) => (
                  <div key={col.title} className="mb-20 lg:mb-40 2xl:mb-70">
                    <div className="overlay-title">
                      <h3
                        className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed font-bold"
                        dangerouslySetInnerHTML={{ __html: col.title }}
                      />
                    </div>
                    <ul>
                      {col.links.map(({ link }) => (
                        <li className="block overlay-link" key={link.url}>
                          {link.url.charAt(0) === `/` ? (
                            <StyledLink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              to={link.url}
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          ) : (
                            <StyledALink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              href={link.url}
                              target="_blank"
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              {overlayColumn4Items &&
                overlayColumn4Items.map((col) => (
                  <div
                    key={col.title}
                    className="lg:hidden mb-20 lg:mb-40 2xl:mb-70"
                  >
                    <div className="overlay-title">
                      <h3
                        className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed font-bold"
                        dangerouslySetInnerHTML={{ __html: col.title }}
                      />
                    </div>
                    <ul>
                      {col.links.map(({ link }) => (
                        <li className="block overlay-link" key={link.url}>
                          {link.url.charAt(0) === `/` ? (
                            <StyledLink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              to={link.url}
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          ) : (
                            <StyledALink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              href={link.url}
                              target="_blank"
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
            <div className="hidden lg:block w-1/2 lg:w-1/4 px-35 2xl:px-50">
              {overlayColumn4Items &&
                overlayColumn4Items.map((col) => (
                  <div key={col.title} className="mb-20 lg:mb-40 2xl:mb-70">
                    <div className="overlay-title">
                      <h3
                        className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed font-bold"
                        dangerouslySetInnerHTML={{ __html: col.title }}
                      />
                    </div>
                    <ul>
                      {col.links.map(({ link }) => (
                        <li className="block overlay-link" key={link.url}>
                          {link.url.charAt(0) === `/` ? (
                            <StyledLink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              to={link.url}
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          ) : (
                            <StyledALink
                              className="text-18 sm:text-15 xl:text-18 2xl:text-20 3xl:text-22 4xl:text-25 leading-relaxed"
                              href={link.url}
                              target="_blank"
                              dangerouslySetInnerHTML={{ __html: link.title }}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
          <div className="overlay-bottom flex flex-wrap items-end -mx-35 2xl:-mx-50 text-16 4xl:text-19 pt-50 lg:pt-80 pb-40 md:pb-0">
            <div className="w-full sm:w-1/2 lg:w-1/4 px-35 2xl:px-50 ">
              <h3 className="font-bold text-16 4xl:text-19">
                Auction House London
              </h3>
              <div
                className="leading-normal max-w-250"
                dangerouslySetInnerHTML={{ __html: address }}
              />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/4 px-35 2xl:px-50">
              <div className="">
                Tel:{` `}
                <StyledALink
                  className="text-white font-bold"
                  target="_blank"
                  href={`tel:${phoneNumber.replace(/\s/g, ``)}`}
                >
                  {phoneNumber}
                </StyledALink>
              </div>
              <div className="">
                Fax:{` `}
                <StyledALink
                  className="text-white font-bold"
                  target="_blank"
                  href={`tel:${fax.replace(/\s/g, ``)}`}
                >
                  {fax}
                </StyledALink>
                <div className="">
                  Email:{` `}
                  <StyledALink
                    className="text-white font-bold"
                    target="_blank"
                    href={`mailto:${email.replace(/\s/g, ``)}`}
                  >
                    {email}
                  </StyledALink>
                </div>
              </div>
            </div>
            <div className="hidden lg:block w-1/4 px-35 2xl:px-50" />
            <div className="hidden w-1/4 px-35 2xl:px-50 text-20 lg:text-30 lg:flex items-center pb-10">
              <a
                className="transition-all duration-300 hover:text-facebook"
                href={facebookProfileUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF />
              </a>
              <a
                className="ml-25 transition-all duration-300 hover:text-twitter"
                href={twitterProfileUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter />
              </a>
              <a
                className="ml-25 transition-all duration-300 hover:text-linkedin"
                href={linkedinProfileUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute top-0 right-0 transition-all duration-300 ${
          scrollY > 100 ? `pt-21 pr-20` : `pt-21 xl:pt-35 pr-20 xl:pr-30 `
        }`}
      >
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
    </StyledOverlayMenu>
  )
}

OverlayMenu.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default OverlayMenu
